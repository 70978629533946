import React, { Suspense, lazy } from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import Loader from '../component/CommonComponent/Loader';
import ErrorBoundry from '../component/ErrorBoundary';

const MaintLayout = lazy(() => import('../container/MainLayout'));
const history = createBrowserHistory({basename: '/'});
const Login = lazy(() => import('../container/login'));
const ForgotPassword = lazy(() => import('../container/forgot'));

const Routes = () => {
    return (
        <Router history={history}>
            <ErrorBoundry>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route exact path="/(|login)" name="Login Page" render={props => <Login {...props}/>} />
                        <Route exact path="/forgot-password" name="Forgot Page" render={props => <ForgotPassword {...props}/>} />
                        <Route exact render={props => <MaintLayout {...props} />} />
                    </Switch>
                </Suspense>
            </ErrorBoundry>
        </Router>
    );
};

export default Routes;