/* League Management Reducer */

import {
    ASSIGN_POLICY_TO_LEAGUE,
    CREATE_LEAGUE,
    CREATE_SEASON,
    DEASSIGN_POLICY_FROM_LEAGUE,
    DEASSIGN_TEAM_FROM_LEAGUE,
    DELETE_LEAGUE,
    GET_LEAGUES_LIST,
    GET_LEAGUES_LIST_FAILURE,
    GET_LEAGUES_LIST_SUCCESS,
    GET_SINGLE_LEAGUE,
    GET_SINGLE_LEAGUE_FAILURE,
    GET_SINGLE_LEAGUE_SUCCESS,
    LIST_EVALUATION_SETTING,
    LIST_EVALUATION_SETTING_FAILURE,
    LIST_EVALUATION_SETTING_SUCCESS,
    LIST_POLICY_LINKED_TO_LEAGUE,
    LIST_POLICY_LINKED_TO_LEAGUE_FAILURE,
    LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS,
    VIEW_LEAGUE,
    VIEW_LEAGUE_FAILURE,
    VIEW_LEAGUE_SUCCESS,
    VIEW_SEASONS,
    VIEW_SEASONS_FAILURE,
    VIEW_SEASONS_SUCCESS,
    GET_TEAM_LINKED_TO_LEAGUE,
    GET_TEAM_LINKED_TO_LEAGUE_SUCCESS,
    GET_TEAM_LINKED_TO_LEAGUE_FAILURE,
} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    leagueList: [],
    deleteLeagueByID: null,
    viewLeagueByID: [],
    viewLeagueSeasonsByID: [],
    createLeague: {},
    dessignByID: null,
    leagueDetails: [],
    listPolicyLinkedToLeague: [],
    evaluationSettingData: [],
    listTeamLinkedToLeague:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_LEAGUES_LIST:
            return {...state, loading: true};
        case GET_LEAGUES_LIST_SUCCESS:
            return {...state, loading: false, leagueList: action.payload};
        case GET_LEAGUES_LIST_FAILURE:
            return {...state, loading: false, leagueList: action.payload};
        case GET_SINGLE_LEAGUE:
            return {...state, loading: true};
        case GET_SINGLE_LEAGUE_SUCCESS:
            return {...state, loading: false, leagueDetails: action.payload};
        case GET_SINGLE_LEAGUE_FAILURE:
            return {...state, loading: false, leagueDetails: action.payload};

        case DELETE_LEAGUE:
            return {...state, loading: false, deleteLeagueByID: action.payload};

        case VIEW_LEAGUE:
            return {...state, loading: false};
        case VIEW_LEAGUE_SUCCESS:
            return {...state, loading: false, viewLeagueByID: action.payload};
        case VIEW_LEAGUE_FAILURE:
            return {...state, loading: false, viewLeagueByID: action.payload};
        case DEASSIGN_TEAM_FROM_LEAGUE:
            return {...state, loading: false, dessignByID: action.payload};
        case CREATE_SEASON:
            return {...state, loading: false};


        case VIEW_SEASONS:
            return {...state, loading: false};
        case VIEW_SEASONS_SUCCESS:
            return {...state, loading: false, viewLeagueSeasonsByID: action.payload};
        case VIEW_SEASONS_FAILURE:
            return {...state, loading: false, viewLeagueSeasonsByID: action.payload};


        case CREATE_LEAGUE:
            return {...state, loading: false};

        case ASSIGN_POLICY_TO_LEAGUE:
            return {...state, loading: false};

        case DEASSIGN_POLICY_FROM_LEAGUE:
            return {...state, loading: false};

        case LIST_POLICY_LINKED_TO_LEAGUE:
            return {...state, loading: false};
        case LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS:
            return {...state, loading: false, listPolicyLinkedToLeague: action.payload};
        case LIST_POLICY_LINKED_TO_LEAGUE_FAILURE:
            return {...state, loading: false, listPolicyLinkedToLeague: action.payload};

        case LIST_EVALUATION_SETTING:
            return {...state, loading: true};
        case LIST_EVALUATION_SETTING_SUCCESS:
            return {...state, loading: false, evaluationSettingData: action.payload};
        case LIST_EVALUATION_SETTING_FAILURE:
            return {...state, loading: false, evaluationSettingData: action.payload};

        case GET_TEAM_LINKED_TO_LEAGUE:
            return {...state, loading: true};
        case GET_TEAM_LINKED_TO_LEAGUE_SUCCESS:
            return {...state, loading: false, listTeamLinkedToLeague: action.payload};
        case GET_TEAM_LINKED_TO_LEAGUE_FAILURE:
            return {...state, loading: false, listTeamLinkedToLeague: action.payload};
        default:
            return state;
    }
};
