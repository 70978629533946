import {all, takeEvery, put,call} from 'redux-saga/effects';
import axios from 'axios';

import {GET_NOTIFICATION_LIST, UPDATE_NOTIFICATION,GAME_END_HOUR_LIST,UPDATE_GAME_END_HOUR} from '../actions/constant';
import {getNotificationListSuccess, getNotificationListFailure, updateNotificationSuccess, updateNotificationFailure,
gameEndHourSuccess, gameEndHourFailure, updateGameEndSuccess, updateGameEndFailure
} from '../actions';
import {apiUrl} from "../utils/helper";

function* getNotificationListRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/v2/notifications/settings`);
        yield put(getNotificationListSuccess(res.data));
    } catch (e) {
        yield put(getNotificationListFailure(e));
    }
}
function* updateNotificationDataRequest(actions) {
    const {data} = actions.payload;
    try {
       yield axios.patch(`${apiUrl}/v2/notifications/settings/${actions.payload.id}`,data);
        yield put(updateNotificationSuccess());
        yield call(actions.payload.back);
    } catch (e) {
        yield put(updateNotificationFailure(e));
    }
}

function* getGameEndHourListRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/v1/cu_config/preference`);
        yield put(gameEndHourSuccess(res.data));
    } catch (e) {
        yield put(gameEndHourFailure(e));
    }
}
function* updateGameEndHourListRequest(actions) {
    const {data} = actions.payload;
    try {
        yield axios.patch(`${apiUrl}/v1/cu_config/preference`,data);
        yield put(updateGameEndSuccess());
    } catch (e) {
        yield put(updateGameEndFailure(e));
    }
}
export function* watchGetNotificatioAPI() {
    yield takeEvery(GET_NOTIFICATION_LIST, getNotificationListRequest);
}
export function* watchUpdateNotificationData(){
    yield takeEvery(UPDATE_NOTIFICATION, updateNotificationDataRequest);
}
export function* watchGetGameHourApi(){
    yield takeEvery(GAME_END_HOUR_LIST,getGameEndHourListRequest);
}
export function* watchUpdateGameHourApi(){
    yield takeEvery(UPDATE_GAME_END_HOUR,updateGameEndHourListRequest);
}
export default function* rootSaga() {
    yield all([
        watchGetNotificatioAPI(),
        watchUpdateNotificationData(),
        watchGetGameHourApi(),
        watchUpdateGameHourApi()
    ]);
}
