import {all, takeEvery, put} from "redux-saga/effects";
import axios from "axios";
import {toast} from 'react-toastify';

import {
    GET_GOVERNING_ENTITY_LIST,
    DELETE_GOVERNING_ENTITY,
    VIEW_GOVERNING_ENTITY,
    CREATE_GOVERNING_ENTITY,
    CREATE_POLICY,
    DELETE_POLICY,
    GET_POLICIES_LIST,
    GET_LEVELS_LIST,
    DELETE_LEVEL,
    CREATE_LEVEL,
    GET_GOV_ENTITY_DATA_BY_ID
} from "../actions/constant";
import {
    getGoverningEntityListFailure,
    getGoverningEntityListSuccess,
    getGoverningEntityList,
    viewGoverningEntityFailure,
    viewGoverningEntitySuccess,
    getPoliciesListSuccess,
    getPoliciesListFailure,
    getLevelsListSuccess,
    getLevelsListFailure,
    getPoliciesList,
    getLevelsList,
    getLeaguesList,
    getGovEntityDataByIdSuccess,
    getGovEntityDataByIdFailure
} from "../actions";
import {apiUrl} from "../utils/helper";

/*= ===== Saga for Governing Entity List ======== */
function* fetchGoverningEntityListRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/v1/governing_organizations`);
        yield put(getGoverningEntityListSuccess(res.data));
    } catch (e) {
        yield put(getGoverningEntityListFailure(e));
    }
}

/*= ===== Saga for Deleting Governing Entity By ID  ======== */
function* deleteGoverningEntityRequest(actions) {
    try {
        yield axios.delete(`${apiUrl}/v1/governing_organizations/${actions.payload.Id}`);
        toast.success("Governing Entity Deleted Successfully");
        yield put(getGoverningEntityList());

    } catch (e) {
        console.log(e);
    }
}

/*= ===== Saga for Viweing Governing Entity By ID  ======== */
function* viewGoverningEntityRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/governing_organizations/${actions.payload.Id}`);
        yield put(viewGoverningEntitySuccess(res.data));
    } catch (e) {
        yield put(viewGoverningEntityFailure(e));
    }
}

/*= ===== Saga for Creating Governing Entity By ID  ======== */
function* createGoverningEntityRequest(actions) {
    try {
        if (actions.payload.id) {
            yield axios.patch(`${apiUrl}/v1/governing_organizations/${actions.payload.id}`, actions.payload.data);
        toast.success("Governing Entity Updated Successfully");
        } else {
            yield axios.post(`${apiUrl}/v1/governing_organizations`, actions.payload.data);
            toast.success("Governing Entity Created Successfully");
        }
        yield actions.payload.his.push({
            pathname: actions.payload.his.location.pathname,
            state: {id: actions.payload.id, name: actions.payload.data.governing_organization.name}
        });
        yield put(getGoverningEntityList());
    } catch (e) {
        console.log(e);
    }
}

/*= ===== Saga for Creating Policy  ======== */
function* createPolicyRequest(actions) {
    const {data, back, policyid} = actions.payload;
    try {
        if (policyid) {
            yield axios.patch(`${apiUrl}/v1/policies/policies/${policyid}`, {'policies_policy': data[0]});
            toast.success("Policy Updated Successfully");
        } else {
            yield axios.post(`${apiUrl}/v1/policies/policies`, {'policies_policy': data[0]});
            toast.success("Policy Created Successfully");
        }
        yield back();
    } catch (e) {
        if(e.data.errors.max_pitches_over_x_days) toast.error(e.data.errors.max_pitches_over_x_days[0]);
        if(e.data.errors.min_rest_if_played_x_consecutive_days) toast.error(e.data.errors.min_rest_if_played_x_consecutive_days[0]);
    }
}

/*= ===== Saga for delete Policy  ======== */
function* deletePolicyRequest(actions) {
    const {Id, Params} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/policies/policies/${Id}`);
        toast.success("Policy Deleted Successfully");
        yield  put(getPoliciesList(Params));
        yield put(getLeaguesList({
            type: 'governing_organization_id',
            id: Params
        }));
    } catch (e) {
        console.log(e);
    }
}

/*= ===== Saga for Getting Policies List  ======== */
function* fetchPoliciesListRequest(actions) {
    const url = actions.payload ? `${apiUrl}/v1/policies/policies?governing_organization_id=${actions.payload}` : `${apiUrl}/v1/policies/policies?governing_organization_id`;
    try {
        const res = yield axios.get(url);
        yield put(getPoliciesListSuccess(res.data));
    } catch (e) {
        yield put(getPoliciesListFailure());
    }
}

/*= ===== Saga for Creating Levels  ======== */
function* createLevelsRequest(actions) {
    try {
        if (actions.payload.id) {
            yield axios.patch(`${apiUrl}/v1/levels/${actions.payload.id}`, actions.payload.obj);
            toast.success("Level Updated Successfully ");
        } else {
            yield axios.post(`${apiUrl}/v1/levels`, actions.payload.obj);
            toast.success("Level Created Successfully ");
        }
        yield actions.payload.back;
        yield  put(getLevelsList(actions.payload.obj.governing_organization_id));

    } catch (e) {
        console.log(e);
    }
}

/*= ===== Saga for delete Level  ======== */
function* deleteLevelsRequest(actions) {
    const {Id, Params} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/levels/${Id}`);
        toast.success("Level Deleted Successfully");
        yield  put(getLevelsList(Params));
    } catch (e) {
        console.log(e);
    }
}

/*= ===== Saga for Getting Levels List  ======== */
function* fetchLevelsListRequest(actions) {
    const url = actions.payload ? `${apiUrl}/v1/levels?governing_organization_id=${actions.payload}` : `${apiUrl}/v1/levels?governing_organization_id`;
    try {
        const res = yield axios.get(url);
        yield put(getLevelsListSuccess(res.data));
    } catch (e) {
        yield put(getLevelsListFailure(e));
    }
}

function* fetchGovEntityByIdRequest(actions) {
    const url = `${apiUrl}/v1/governing_organizations/${actions.payload}`;
    try {
        const res = yield axios.get(url);
        yield put(getGovEntityDataByIdSuccess(res.data));
    } catch (e) {
        yield put(getGovEntityDataByIdFailure(e));
    }
}

/* Watch Governing Entity List */
export function* watchGoverningEntityListAPI() {
    yield takeEvery(GET_GOVERNING_ENTITY_LIST, fetchGoverningEntityListRequest);
}

/* Watch for Deleting Governing Entity */
export function* watchDeleteGoverningEntityAPI() {
    yield takeEvery(DELETE_GOVERNING_ENTITY, deleteGoverningEntityRequest);
}

/* Watch for Viewing Governing Entity */
export function* watchViewGoverningEntityAPI() {
    yield takeEvery(VIEW_GOVERNING_ENTITY, viewGoverningEntityRequest);
}

/* Watch for Creating Governing Entity */
export function* watchCreateGoverningEntityAPI() {
    yield takeEvery(CREATE_GOVERNING_ENTITY, createGoverningEntityRequest);
}

/* Watch for Creating Policy */
export function* watchCreatePolicyAPI() {
    yield takeEvery(CREATE_POLICY, createPolicyRequest);
}


/* Watch for Deleting Policy */
export function* watchDeletePolicyAPI() {
    yield takeEvery(DELETE_POLICY, deletePolicyRequest);
}

/* Watch for Getting List of Policy */
export function* watchPolicyListAPI() {
    yield takeEvery(GET_POLICIES_LIST, fetchPoliciesListRequest);
}

/* Watch for Creating Levels */
export function* watchCreateLevelAPI() {
    yield takeEvery(CREATE_LEVEL, createLevelsRequest);
}

/* Watch for Deleting Levels */
export function* watchDeleteLevelAPI() {
    yield takeEvery(DELETE_LEVEL, deleteLevelsRequest);
}

/* Watch for Getting List of Levels */
export function* watchLevelListAPI() {
    yield takeEvery(GET_LEVELS_LIST, fetchLevelsListRequest);
}

/* Watch for Getting GOV Entity Data By ID */
export function* watchGetGovEntityByIdAPI() {
    yield takeEvery(GET_GOV_ENTITY_DATA_BY_ID, fetchGovEntityByIdRequest);
}

export default function* rootSaga() {
    yield all([
        watchGoverningEntityListAPI(),
        watchDeleteGoverningEntityAPI(),
        watchViewGoverningEntityAPI(),
        watchCreateGoverningEntityAPI(),
        watchCreatePolicyAPI(),
        watchPolicyListAPI(),
        watchDeletePolicyAPI(),
        watchCreateLevelAPI(),
        watchLevelListAPI(),
        watchDeleteLevelAPI(),
        watchGetGovEntityByIdAPI()
    ]);
}
