import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import configureStore from './store';
import App from './App';
import {setAuthToken} from "./utils/axios";
import {getToken} from "./utils/helper";

const MainApp = () => {
    useEffect(() => {
        setAuthToken(getToken());
    });
    return (
        <Provider store={configureStore()}>
            <App/>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />

        </Provider>
    );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
