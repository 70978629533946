/* Actions of Team Management */

import {
    GET_TEAM_LIST,
    GET_TEAM_LIST_SUCCESS,
    GET_TEAM_LIST_FAILURE,

    DELETE_TEAM,
    CREATE_TEAM,

    ASSIGN_PLAYER_TO_TEAM,
    ASSIGN_COACH_TO_TEAM,
    ASSIGN_COUNTER_TO_TEAM,

    VIEW_TEAM_COACH_LIST,
    VIEW_TEAM_COACH_LIST_SUCEESS,
    VIEW_TEAM_COACH_LIST_FAILURE,

    VIEW_TEAM_PLAYER_LIST,
    VIEW_TEAM_PLAYER_LIST_SUCCESS,
    VIEW_TEAM_PLAYER_LIST_FAILURE,

    VIEW_TEAM_COUNTER_LIST,
    VIEW_TEAM_COUNTER_LIST_SUCCESS,
    VIEW_TEAM_COUNTER_LIST_FAILURE,

    SHOW_SINGLE_TEAM,
    SHOW_SINGLE_TEAM_FAILURE,
    SHOW_SINGLE_TEAM_SUCCESS,

    GET_TEAM_GAME_LIST,
    GET_TEAM_GAME_LIST_FAILURE,
    GET_TEAM_GAME_LIST_SUCCESS,

    VIEW_ALL_SEASONS,
    VIEW_ALL_SEASONS_SUCCESS,
    VIEW_ALL_SEASONS_FAILURE,


    LIST_PLAYER_LINKED_TO_TEAM,
    LIST_PLAYER_LINKED_TO_TEAM_SUCCESS,
    LIST_PLAYER_LINKED_TO_TEAM_FAILURE,

    LIST_COACH_LINKED_TO_TEAM,
    LIST_COACH_LINKED_TO_TEAM_SUCCESS,
    LIST_COACH_LINKED_TO_TEAM_FAILURE,

    LIST_COUNTER_LINKED_TO_TEAM,
    LIST_COUNTER_LINKED_TO_TEAM_SUCCESS,
    LIST_COUNTER_LINKED_TO_TEAM_FAILURE,

    DEASSIGN_COACH_FROM_TEAM,
    DEASSIGN_COUNTER_FROM_TEAM,
    CREATE_NON_LEAGUE,
    LIST_TEAM_WITHOUT_SERIALIZATION,
    LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS,
    LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE, DEASSIGN_PLAYER_FROM_TEAM

} from "./constant";

/*= ======= List of  Team Actions ========== */
export const getTeamList = (payload) => ({
    type: GET_TEAM_LIST,
    payload
});

export const getTeamListSuccess = (payload) => ({
    type: GET_TEAM_LIST_SUCCESS,
    payload
});

export const getTeamListFailure = (payload) => ({
    type: GET_TEAM_LIST_FAILURE,
    payload
});

/*= ======= View Team by ID Actions ========== */
export const viewTeamPlayer = (payload) => ({
    type: VIEW_TEAM_PLAYER_LIST,
    payload
});

export const getTeamWithoutSerilization = (payload) => ({
    type: LIST_TEAM_WITHOUT_SERIALIZATION,
    payload
});

export const getTeamWithoutSerilizationFailure = (payload) => ({
    type: LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE,
    payload
});

/*= ======= View Team by ID Actions ========== */
export const getTeamWithoutSerilizationSuccess = (payload) => ({
    type: LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS,
    payload
});
export const viewTeamPlayerSuccess = (payload) => ({
    type: VIEW_TEAM_PLAYER_LIST_SUCCESS,
    payload
});

export const viewTeamPlayerFailure = (payload) => ({
    type: VIEW_TEAM_PLAYER_LIST_FAILURE,
    payload
});

/*= ======= View Team Coach by ID Actions ========== */
export const viewTeamCoach = (payload) => ({
    type: VIEW_TEAM_COACH_LIST,
    payload
});

export const viewTeamCoachSuccess = (payload) => ({
    type: VIEW_TEAM_COACH_LIST_SUCEESS,
    payload
});

export const viewTeamCoachFailure = (payload) => ({
    type: VIEW_TEAM_COACH_LIST_FAILURE,
    payload
});

/*= ======= View Team Counter by ID Actions ========== */
export const viewTeamCounter = (payload) => ({
    type: VIEW_TEAM_COUNTER_LIST,
    payload
});

export const viewTeamCounterSuccess = (payload) => ({
    type: VIEW_TEAM_COUNTER_LIST_SUCCESS,
    payload
});

export const viewTeamCounterFailure = (payload) => ({
    type: VIEW_TEAM_COUNTER_LIST_FAILURE,
    payload
});


/*= ======= View Team Counter by ID Actions ========== */
export const showSingleTeam = (payload) => ({
    type: SHOW_SINGLE_TEAM,
    payload
});

export const showSingleTeamSuccess= (payload) => ({
    type: SHOW_SINGLE_TEAM_SUCCESS,
    payload
});

export const showSingleTeamFailure = (payload) => ({
    type: SHOW_SINGLE_TEAM_FAILURE,
    payload
});

/*= ======= Delete Team by ID Actions ========== */
export const deleteTeam = (payload) => ({
    type: DELETE_TEAM,
    payload,
});

/* Create team */
export const createTeam = (payload) => ({
    type: CREATE_TEAM,
    payload,
});

/* Assign player to  team */
export const assignPlayerToTeam = (payload) => ({
    type: ASSIGN_PLAYER_TO_TEAM,
    payload,
});

/* Assign Coach To Team */
export const assignCoachToTeam = (payload) => ({
    type: ASSIGN_COACH_TO_TEAM,
    payload,
});

/* Assign Coach To Team */
export const assignCounterToTeam = (payload) => ({
    type: ASSIGN_COUNTER_TO_TEAM,
    payload,
});

/* Team Game list */
export const getTeamGameList = (payload) => ({
    type: GET_TEAM_GAME_LIST,
    payload
});

export const getTeamGameListSuccess = (payload) => ({
    type: GET_TEAM_GAME_LIST_SUCCESS,
    payload
});

export const getTeamGameListFailure = (payload) => ({
    type: GET_TEAM_GAME_LIST_FAILURE,
    payload
});

export const getAllSeasonList = (payload) => ({
    type: VIEW_ALL_SEASONS,
    payload
});


export const getAllSeasonsSuccess = (payload) => ({
    type: VIEW_ALL_SEASONS_SUCCESS,
    payload
});

export const getAllSeasonsFailure = (payload) => ({
    type: VIEW_ALL_SEASONS_FAILURE,
    payload
});

/* Actions player linked to team */

export const getPlayerLinkedToTeam = (payload) => ({
    type: LIST_PLAYER_LINKED_TO_TEAM,
    payload
});


export const getPlayerLinkedToTeamSuccess= (payload) => ({
    type: LIST_PLAYER_LINKED_TO_TEAM_SUCCESS,
    payload
});

export const getPlayerLinkedToTeamFailure = (payload) => ({
    type: LIST_PLAYER_LINKED_TO_TEAM_FAILURE,
    payload
});

/* Action coach linked with team */

export const getCoachLinkedToTeam = (payload) => ({
    type: LIST_COACH_LINKED_TO_TEAM,
    payload
});


export const getCoachLinkedToTeamSuccess= (payload) => ({
    type: LIST_COACH_LINKED_TO_TEAM_SUCCESS,
    payload
});

export const getCoachLinkedToTeamFailure = (payload) => ({
    type: LIST_COACH_LINKED_TO_TEAM_FAILURE,
    payload
});

/* List counter attched with team */

export const getCounterLinkedToTeam = (payload) => ({
    type: LIST_COUNTER_LINKED_TO_TEAM,
    payload
});


export const getCounterLinkedToTeamSuccess= (payload) => ({
    type: LIST_COUNTER_LINKED_TO_TEAM_SUCCESS,
    payload
});

export const getCounterLinkedToTeamFailure = (payload) => ({
    type: LIST_COUNTER_LINKED_TO_TEAM_FAILURE,
    payload
});

/* Deassign coach from team */


export const deAssignCoachFromTeam = (payload) => ({
    type: DEASSIGN_COACH_FROM_TEAM,
    payload
});

/* Deassign counter from team */

export const deAssignCounterFromTeam = (payload) => ({
    type: DEASSIGN_COUNTER_FROM_TEAM,
    payload
});

/* Deassign player from team */

export const deAssignPlayerFromTeam = (payload) => ({
    type: DEASSIGN_PLAYER_FROM_TEAM,
    payload
});

/* Create Non-League game */

export const createNonLeagueGame = (payload) => ({
    type: CREATE_NON_LEAGUE,
    payload
});
