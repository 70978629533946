/* Actions of Player Management */

import {
    GET_PLAYERS_LIST,
    GET_PLAYERS_LIST_SUCCESS,
    GET_PLAYERS_LIST_FAILURE,

    DELETE_PLAYER,
    CREATE_PLAYER,

    VIEW_PLAYER,
    VIEW_PLAYER_SUCCESS,
    VIEW_PLAYER_FAILURE

} from "./constant";

/* ======== List of  Player  Actions ========== */

export const getPlayerList = (payload) => ({
    type: GET_PLAYERS_LIST,
    payload
});

export const getPlayerListSuccess = (payload) => ({
    type: GET_PLAYERS_LIST_SUCCESS,
    payload
});

export const getPlayerListFailure = (payload) => ({
    type: GET_PLAYERS_LIST_FAILURE,
    payload
});

/* ======== Delete  Player By ID  Actions ========== */

export const deletePlayer = (payload) => ({
    type: DELETE_PLAYER,
    payload
});


/* =========Create Player ============= */
export const createPlayer = (payload) => ({
    type:CREATE_PLAYER ,
    payload
});

/* ======== Update  Player By ID  Actions ========== */

export const viewPlayer = () => ({
    type: VIEW_PLAYER
});

export const viewPlayerSuccess = (payload) => ({
    type: VIEW_PLAYER_SUCCESS,
    payload
});

export const viewPlayerFailure = (payload) => ({
    type: VIEW_PLAYER_FAILURE,
    payload
});

