import {
    GET_GAME_LIST,
    GET_GAME_LIST_SUCCESS,
    GET_GAME_LIST_FAILURE,

    CREATE_OR_EDIT_GAME,
    EMPTY_GAME_LIST
} from '../actions/constant';

const INIT_STATE = {
    gameList: [],
    loading: false
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case GET_GAME_LIST:
            return { ...state, loading: true };
        case EMPTY_GAME_LIST:
            return { ...state, gameList: [] };
        case GET_GAME_LIST_SUCCESS:
            return { ...state, loading: false, gameList: state.gameList.concat(action.payload) };
        case GET_GAME_LIST_FAILURE:
            return { ...state, loading: false, gameList: action.payload };
        case CREATE_OR_EDIT_GAME:
            return { ...state, loading: !!(action.payload) };
        default:
            return state;
    }
};