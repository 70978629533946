import {all, takeEvery, put} from "redux-saga/effects";
import axios from "axios";

import {
    GET_GOVERNING_ENTITY_LIST,
} from "../actions/constant";
import {
    getGoverningEntityListFailure,
    getGoverningEntityListSuccess,
} from "../actions";
import {apiUrl} from "../utils/helper";

export function* fetchGoverningEntityListRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/v1/governing_organizations`);
        yield put(getGoverningEntityListSuccess(res.data));
    } catch (e) {
        yield put(getGoverningEntityListFailure(e));
    }
}

/* Watch Governing Entity List */
export function* watchGoverningEntityListAPI() {
    yield takeEvery(GET_GOVERNING_ENTITY_LIST, fetchGoverningEntityListRequest);
}

export default function* rootSaga() {
    yield all([
        watchGoverningEntityListAPI()
    ]);
}
