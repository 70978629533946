/* Coach Management Reducer */

import {
    GET_COUNTER_LIST,
    GET_COUNTER_LIST_SUCCESS,
    GET_COUNTER_LIST_FAILURE,

    DELETE_COUNTER,
    CREATE_COUNTER,

    VIEW_COUNTER,
    VIEW_COUNTER_SUCCESS,
    VIEW_COUNTER_FAILURE

} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    counterList:[],
    viewCounterByID:null,
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {

        case GET_COUNTER_LIST:
            return { ...state, loading: true };
        case GET_COUNTER_LIST_SUCCESS:
            return { ...state, loading: false, counterList: action.payload };
        case GET_COUNTER_LIST_FAILURE:
            return { ...state, loading: false, counterList: action.payload };

        case DELETE_COUNTER:
            return { ...state, loading: false};
        case CREATE_COUNTER:
            return { ...state, loading: false};

        case VIEW_COUNTER:
            return { ...state, loading: false };
        case VIEW_COUNTER_SUCCESS:
            return { ...state, loading: false, viewCounterByID: action.payload };
        case VIEW_COUNTER_FAILURE:
            return { ...state, loading: false, viewCounterByID: action.payload };
        default:
            return state;
    }
};
