 /* Governing Entity Reducer */

import {
    GET_GOVERNING_ENTITY_LIST,
    GET_GOVERNING_ENTITY_LIST_SUCCESS,
    GET_GOVERNING_ENTITY_LIST_FAILURE,

    DELETE_GOVERNING_ENTITY,

    VIEW_GOVERNING_ENTITY,
    VIEW_GOVERNING_ENTITY_SUCCESS,
    VIEW_GOVERNING_ENTITY_FAILURE,

    CREATE_GOVERNING_ENTITY,

    CREATE_POLICY,

    GET_POLICIES_LIST,
    GET_POLICIES_LIST_SUCCESS,
    GET_POLICIES_LIST_FAILURE,

    DELETE_POLICY,

    VIEW_POLICY,
    VIEW_POLICY_FAILURE,
    VIEW_POLICY_SUCCESS,

    CREATE_LEVEL,

    GET_LEVELS_LIST,
    GET_LEVELS_LIST_SUCCESS,
    GET_LEVELS_LIST_FAILURE,

    VIEW_LEVEL,
    VIEW_LEVEL_SUCCESS,
    VIEW_LEVEL_FAILURE,

    DELETE_LEVEL,

    GET_GOV_ENTITY_DATA_BY_ID,
    GET_GOV_ENTITY_DATA_BY_ID_SUCCESS,
    GET_GOV_ENTITY_DATA_BY_ID_FAILURE
} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    governingEntityList: [],
    deleteGoverningEntityByID: null,
    viewGoverningEntityData: [],
    createPolicy: {},
    updatePolicyByID: null,
    policiesList: [],
    viewPolicyByID: null,
    deleteLevelByID: null,
    levelsList: [],
    levelsListById:[],
    viewLevelByID: null,
    getGovEntityData: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_GOVERNING_ENTITY_LIST:
            return {...state, loading: true};
        case GET_GOVERNING_ENTITY_LIST_SUCCESS:
            return {...state, loading: false, governingEntityList: action.payload};
        case GET_GOVERNING_ENTITY_LIST_FAILURE:
            return {...state, loading: false, governingEntityList: action.payload};

        case DELETE_GOVERNING_ENTITY:
            return {...state, loading: false, deleteGoverningEntityByID: action.payload};

        case VIEW_GOVERNING_ENTITY:
            return {...state, loading: false};
        case VIEW_GOVERNING_ENTITY_SUCCESS:
            return {...state, loading: false, viewGoverningEntityData: action.payload};
        case VIEW_GOVERNING_ENTITY_FAILURE:
            return {...state, loading: false, viewGoverningEntityData: action.payload};

        case CREATE_GOVERNING_ENTITY:
            return {...state, loading: true};

        case CREATE_POLICY:
            return {...state, loading: false};

        case VIEW_POLICY:
            return {...state, loading: false};
        case VIEW_POLICY_SUCCESS:
            return {...state, loading: false, viewPolicyByID: action.payload};
        case VIEW_POLICY_FAILURE:
            return {...state, loading: false, viewPolicyByID: action.payload};


        case GET_POLICIES_LIST:
            return {...state, loading: false};
        case GET_POLICIES_LIST_SUCCESS:
            return {...state, loading: false, policiesList: action.payload};
        case GET_POLICIES_LIST_FAILURE:
            return {...state, loading: false, policiesList: action.payload};

        case DELETE_POLICY:
            return {...state, loading: false, deletePolicyByID: action.payload};

        case CREATE_LEVEL:
            return {...state, loading: false};

        case VIEW_LEVEL:
            return {...state, loading: false};
        case VIEW_LEVEL_SUCCESS:
            return {...state, loading: false, viewLevelByID: action.payload};
        case VIEW_LEVEL_FAILURE:
            return {...state, loading: false, viewLevelByID: action.payload};

        case GET_LEVELS_LIST:
            return {...state, loading: false};
        case GET_LEVELS_LIST_SUCCESS:
            return {...state, loading: false, levelsList: action.payload};
        case GET_LEVELS_LIST_FAILURE:
            return {...state, loading: false, levelsList: action.payload};

        case DELETE_LEVEL:
            return {...state, loading: false, deleteLevelByID: action.payload};

        case GET_GOV_ENTITY_DATA_BY_ID:
            return { ...state, loading: false };
        case GET_GOV_ENTITY_DATA_BY_ID_SUCCESS:
            return { ...state, loading: false, getGovEntityData: action.payload };
        case GET_GOV_ENTITY_DATA_BY_ID_FAILURE:
            return { ...state, loading: false, getGovEntityData: action.payload };

        default:
            return state;
    }
};
