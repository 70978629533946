import { all } from 'redux-saga/effects';

import LoginSaga from './loginSaga';
import GoveringEntitySaga from './governingEntitySaga';
import LeagueSaga from './leagueManagementSaga';
import TeamSaga from './teamManagementSaga';
import CoachSaga from './coachManagementSaga';
import CounterSaga from './counterManagementSaga';
import PlayerSaga from './playerManagementSaga';
import GameSaga from './gameManagementSaga';
import SystemConfigSaga from './systemConfigSaga';
import SystemConfigurationSaga from './systemConfigurationSaga';

export default function* rootSaga() {
    yield all([
        LoginSaga(),
        GoveringEntitySaga(),
        LeagueSaga(),
        TeamSaga(),
        CoachSaga(),
        CounterSaga(),
        PlayerSaga(),
        GameSaga(),
        SystemConfigSaga(),
        SystemConfigurationSaga()
    ]);
}
