import {
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,

    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,

    GAME_END_HOUR_LIST,
    GAME_END_HOUR_SUCCESS,
    GAME_END_HOUR_FALIURE,
    UPDATE_GAME_END_HOUR,
    UPDATE_GAME_END_HOUR_SUCCESS,
    UPDATE_GAME_END_HOUR_FALIURE
} from '../actions/constant';

const INIT_STATE = {
    loading: false,
    notificationData: null,
    gameEndHourData:{},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_LIST:
            return {...state, loading: true};
        case GET_NOTIFICATION_LIST_SUCCESS:
            return {...state, loading: false, notificationData: action.payload};
        case GET_NOTIFICATION_LIST_FAILURE:
            return {...state, loading: false, notificationData: action.payload};
        case UPDATE_NOTIFICATION:
            return {...state, loading: true};
        case UPDATE_NOTIFICATION_SUCCESS:
            return {...state, loading: false};
        case UPDATE_NOTIFICATION_FAILURE:
            return {...state, loading: false};

        case GAME_END_HOUR_LIST:
            return {...state,loading: true};
        case GAME_END_HOUR_SUCCESS:
            return {...state,loading: false,gameEndHourData:action.payload};
        case GAME_END_HOUR_FALIURE:
            return {...state,loading: false,gameEndHourData:action.payload};
        case UPDATE_GAME_END_HOUR:
            return {...state,loading: false};
        case UPDATE_GAME_END_HOUR_SUCCESS:
            return {...state,loading: false};
        case UPDATE_GAME_END_HOUR_FALIURE:
            return {...state,loading: false};
        default:
            return state;
    }
};
