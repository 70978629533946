/* Actions of League */

import {
    ASSIGN_POLICY_TO_LEAGUE,
    ASSIGN_TEAM_TO_LEAGUE,
    CREATE_EVALUATION,
    CREATE_LEAGUE,
    CREATE_SEASON,
    DEASSIGN_POLICY_FROM_LEAGUE,
    DEASSIGN_TEAM_FROM_LEAGUE,
    DELETE_LEAGUE,
    GET_LEAGUES_LIST,
    GET_LEAGUES_LIST_FAILURE,
    GET_LEAGUES_LIST_SUCCESS,
    GET_SINGLE_LEAGUE,
    GET_SINGLE_LEAGUE_FAILURE,
    GET_SINGLE_LEAGUE_SUCCESS,
    VIEW_LEAGUE,
    VIEW_LEAGUE_FAILURE,
    VIEW_LEAGUE_SUCCESS,
    VIEW_SEASONS,
    VIEW_SEASONS_FAILURE,
    VIEW_SEASONS_SUCCESS,
    LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS,
    LIST_POLICY_LINKED_TO_LEAGUE_FAILURE,
    LIST_POLICY_LINKED_TO_LEAGUE,
    LIST_EVALUATION_SETTING,
    LIST_EVALUATION_SETTING_SUCCESS,
    LIST_EVALUATION_SETTING_FAILURE,
    GET_TEAM_LINKED_TO_LEAGUE,
    GET_TEAM_LINKED_TO_LEAGUE_SUCCESS,
    GET_TEAM_LINKED_TO_LEAGUE_FAILURE

} from "./constant";

/*= ======= List of Leagues Actions ======== */
export const getLeaguesList = payload => ({
    type: GET_LEAGUES_LIST,
    payload
});

export const getLeaguesListSuccess = payload => ({
    type: GET_LEAGUES_LIST_SUCCESS,
    payload
});

export const getLeaguesListFailure = payload => ({
    type: GET_LEAGUES_LIST_FAILURE,
    payload
});

/*= ======= List of Leagues Actions ======== */
export const getTeamLinkedToLeague = payload => ({
    type: GET_TEAM_LINKED_TO_LEAGUE,
    payload
});

export const getTeamLinkedToLeagueSuccess = payload => ({
    type: GET_TEAM_LINKED_TO_LEAGUE_SUCCESS,
    payload
});

export const getTeamLinkedToLeagueFailure = payload => ({
    type: GET_TEAM_LINKED_TO_LEAGUE_FAILURE,
    payload
});

/*= ======= Delete League By ID Actions ======= */
export const deleteLeague = payload => ({
    type: DELETE_LEAGUE,
    payload
});

/*= ======= View League By ID Actions =========== */
export const viewLeague = payload => ({
    type: VIEW_LEAGUE,
    payload
});
export const getSingleLeague = payload => ({
    type: GET_SINGLE_LEAGUE,
    payload
});
export const getSingleLeaguesFailure = payload => ({
    type: GET_SINGLE_LEAGUE_FAILURE,
    payload
});
export const getSingleLeaguesSuccess = payload => ({
    type: GET_SINGLE_LEAGUE_SUCCESS,
    payload
});

export const viewLeagueSuccess = payload => ({
    type: VIEW_LEAGUE_SUCCESS,
    payload
});

export const viewLeagueFailure = payload => ({
    type: VIEW_LEAGUE_FAILURE,
    payload
});

/*= ======= Assign Deassign Team From League By ID Actions =========== */
export const deassignTeamFromLeague = payload => ({
    type: DEASSIGN_TEAM_FROM_LEAGUE,
    payload
});

export const assignTeamToLeague = payload => ({
    type: ASSIGN_TEAM_TO_LEAGUE,
    payload
});
/*= ======= League Seasons =========== */
export const viewSeasons = payload => ({
    type: VIEW_SEASONS,
    payload
});

export const createSeason = payload => ({
    type: CREATE_SEASON,
    payload
});

export const viewSeasonsSuccess = payload => ({
    type: VIEW_SEASONS_SUCCESS,
    payload
});

export const viewSeasonsFailure = payload => ({
    type: VIEW_SEASONS_FAILURE,
    payload
});

/*= ======= Create League By ID Actions =========== */
export const createLeague = payload => ({
    type: CREATE_LEAGUE,
    payload
});

/* Assign Policy to league */
export const assignPolicyToLeague = payload => ({
    type: ASSIGN_POLICY_TO_LEAGUE,
    payload
});

/* DeAssign Policy to league */
export const deassignPolicyToLeague = payload => ({
    type: DEASSIGN_POLICY_FROM_LEAGUE,
    payload
});
/* Create Evluation */
export const createEvaluationRequest = payload => ({
    type: CREATE_EVALUATION,
    payload
});

// List league linked to the policy
export const getListLeagueLinkedToPolicy = payload => ({
    type:LIST_POLICY_LINKED_TO_LEAGUE ,
    payload
});

export const getListLeagueLinkedToPolicySuccess = payload => ({
    type:LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS ,
    payload
});

export const getListLeagueLinkedToPolicyFailure = payload => ({
    type:LIST_POLICY_LINKED_TO_LEAGUE_FAILURE ,
    payload
});

export const getEvaluationSetting = payload => ({
    type:LIST_EVALUATION_SETTING,
    payload
});

export const getEvaluationSettingSuccess = payload => ({
    type:LIST_EVALUATION_SETTING_SUCCESS,
    payload
});

export const getEvaluationSettingFailure = payload => ({
    type:LIST_EVALUATION_SETTING_FAILURE,
    payload
});
