import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE
} from "../actions/constant";

const INIT_STATE = {
  loading: false,
  user: {},
  userDetails: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, user: action.payload };
    case GET_USER_DETAILS:
      return { ...state, loading: true };
    case GET_USER_DETAILS_SUCCESS:
      return { ...state, loading: false, userDetails: action.payload };
    case GET_USER_DETAILS_FAILURE:
      return { ...state, loading: false, userDetails: action.payload };
    default:
      return state;
  }
};
