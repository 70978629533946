/* Reducer for Leagues */

import {all, takeEvery, put, call} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from "react-toastify";
import {apiUrl} from "../utils/helper";
import {
    GET_LEAGUES_LIST,
    DELETE_LEAGUE,
    VIEW_LEAGUE,
    DEASSIGN_TEAM_FROM_LEAGUE,
    VIEW_SEASONS,
    CREATE_LEAGUE,
    ASSIGN_POLICY_TO_LEAGUE,
    DEASSIGN_POLICY_FROM_LEAGUE,
    ASSIGN_TEAM_TO_LEAGUE,
    CREATE_SEASON,
    GET_SINGLE_LEAGUE,
    CREATE_EVALUATION,
    LIST_POLICY_LINKED_TO_LEAGUE,
    LIST_EVALUATION_SETTING,
    GET_TEAM_LINKED_TO_LEAGUE
} from '../actions/constant';

import {
    getSingleLeaguesFailure,
    getSingleLeaguesSuccess,
    getLeaguesListSuccess,
    getLeaguesListFailure,
    getLeaguesList,

    viewLeagueSuccess,
    viewLeagueFailure,
    viewLeague,

    viewSeasonsSuccess,
    viewSeasonsFailure,

    getListLeagueLinkedToPolicySuccess,
    getListLeagueLinkedToPolicyFailure,
    getEvaluationSettingSuccess,
    getEvaluationSettingFailure,
    viewSeasons,
    getTeamLinkedToLeagueSuccess,
    getTeamLinkedToLeagueFailure
} from "../actions";

/* ====== Saga for League List ======== */
function* fetchLeagueListRequest(actions) {
    let url;
    if(actions.payload && actions.payload.type === "filter")
        url = `${apiUrl}/v1/leagues?state${actions.payload.state ? `=${actions.payload.state}` : ''}&city${actions.payload.city ? `=${actions.payload.city}` : ''}&keywords${actions.payload.keywords ? `=${actions.payload.keywords}` : ''}`;
    else url = actions.payload ? `${apiUrl}/v1/leagues?${actions.payload.type}=${actions.payload.id ? actions.payload.id : actions.payload}` : `${apiUrl}/v1/leagues`;
    try {
        const res = yield axios.get(url);
        yield put(getLeaguesListSuccess(res.data));
    } catch (e) {
        yield put(getLeaguesListFailure(e));
    }
}

// /*= ===== Saga for Deleting League y By ID  ========*/
function* deleteLeagueRequest(actions) {
    const {Id, Params,Back} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/leagues/${Id}`);
        yield put(getLeaguesList(Params));
        toast.success("League Deleted Successfully");
        yield Back && Back();
    } catch (e) {
        console.log(e);
    }
}

// /*= ===== Saga for Get League Details  By ID  ========*/
function* getLeagueDetailsRequest(actions) {
    const url = `${apiUrl}/v1/leagues/${actions.payload}`;
    try {
        const res = yield axios.get(url);
        yield put(getSingleLeaguesSuccess(res.data));
    } catch (e) {
        yield put(getSingleLeaguesFailure(e));
    }
}

// /*= ===== Saga for Viewing League  By ID  ========*/
function* ViewLeagueRequest(actions) {
    const url = `${apiUrl}/v1/teams?league_id=${actions.payload}`;
    try {
        const res = yield axios.get(url);
        yield put(viewLeagueSuccess(res.data));
    } catch (e) {
        yield put(viewLeagueFailure(e));
    }
}

function* fetchTeamLinkedToLeague(actions) {

    try {
        const res = yield axios.get(`${apiUrl}/v1/league_teams?${actions.payload.type}=${actions.payload.id}`);
        yield put(getTeamLinkedToLeagueSuccess(res.data));
    } catch (e) {
        yield put(getTeamLinkedToLeagueFailure(e));
    }
}

// /*====== Saga for Creating League    ========*/
function* deassignLeagueTeamRequest(actions) {
    const {Id, Params,Array} = actions.payload;
    const pass= Array && Array.map(item => item.team_id === Id && item.id );
    const ans=  pass.filter(word => typeof(word)=== "number").toString().split(',')[0];
    try {
        yield axios.delete(`${apiUrl}/v1/league_teams/${ans}`);
        yield put(viewLeague(Params));
    } catch (e) {
        console.log(e);
    }
}

function* assignLeagueTeamRequest(actions) {
    const {payload} = actions;
    try {
        yield axios.post(`${apiUrl}/v1/league_teams` ,payload);
        yield put(viewLeague(payload.league_teams.league_id));
    } catch (e) {
        console.log(e);
    }
}

// /*= ===== Saga for Viweing League Season  By ID  ========*/
function* ViewLeagueSeasonRequest(actions) {
    const url = actions.payload ? `${apiUrl}/v1/seasons?${actions.payload.type}=${actions.payload.id}` : `${apiUrl}/v1/seasons`;
    try {
        const res=yield axios.get(url);
        yield put(viewSeasonsSuccess(res.data));
    } catch (e) {
        yield put(viewSeasonsFailure(e));
    }
}

/* ====== Saga for Creating League Season   ======== */
function* CreateLeagueSeasonRequest(actions) {
    const {data} = actions.payload;
    try {
        if(data && data.Create) {
            yield axios.post(`${apiUrl}/v1/seasons`, data);
            yield viewSeasons({type: 'league_id', id: data && data.seasonId});
            yield  actions.payload.back;
        }else {
            yield axios.patch(`${apiUrl}/v1/seasons/${data.seasonId}`, data);
            yield viewSeasons({type: 'league_id', id: data && data.seasonId});
            yield  actions.payload.back;
        }

    } catch (e) {
        console.log(e);
    }
}

 /*= ===== Saga for Creating Evaluation  ======== */
function* createEvaluation(actions) {
    const {data,back , eveluationId} =actions.payload;
    try {
        if(eveluationId) {
            yield axios.patch(`${apiUrl}/v1/evaluation_settings/${eveluationId}`,data);
        }else {
            yield axios.post(`${apiUrl}/v1/evaluation_settings`,data);
        }
        yield back;
    } catch (e) {
        console.log(e);
    }
}

/* ====== Saga for Creating League    ======== */
function* CreateLeagueRequest(actions) {
    const {data} = actions.payload;
    try {
        if (actions.payload.data.leagueId) {
            yield axios.patch(`${apiUrl}/v1/leagues/${actions.payload.data.leagueId}`, data.league);
            if(data.gov_id.id) yield put(getLeaguesList(data.gov_id));
            else yield put(getLeaguesList());
            yield call(data.back);
            toast.success("League Updated Successfully");
        } else {
            yield axios.post(`${apiUrl}/v1/leagues`, data.league);
            if(data.gov_id.id) yield put(getLeaguesList(data.gov_id));
            else yield put(getLeaguesList());
            yield call(data.back);
            toast.success("League Created Successfully");
        }
        /* yield actions.payload.his && actions.payload.his.push({
            pathname: actions.payload.his.location.pathname,
            state: {
                id: actions.payload.id,
                name: actions.payload.data.league.data.name,
                uuid: actions.payload.data.league.data.uuid,
                state: actions.payload.data.league.data.state,
                city: actions.payload.data.league.data.city,
                paid: actions.payload.data.league.data.paid,
                active: actions.payload.data.league.data.active,
            }
        }); */
    } catch (e) {
        toast.error("Something went wrong");
    }
}

/* Saga for assigning and deassigin league  */
function* AssignPolicyToLeagueRequest(actions) {

    try {
        yield axios.post(`${apiUrl}/v1/league_policies`, actions.payload.data);
        yield put(getLeaguesList({type:"governing_organization_id",id:actions.payload.governing_organization_id}));
        toast.success("Policy Assigned Successfully");
    } catch (e) {
        console.log(e);
    }
}

/* Saga for Deassigning and deassigin league  */
function* DeAssignPolicyToLeagueRequest(actions) {
    // eslint-disable-next-line camelcase
    const {policy_id,governing_organization_id}=actions.payload;
    try {
        // eslint-disable-next-line camelcase
        yield axios.delete(`${apiUrl}/v1/league_policies/${policy_id}`);
        yield put(getLeaguesList({type:"governing_organization_id",id:governing_organization_id}));
        toast.success("Policy De-Assigned Successfully");
    } catch (e) {
        console.log(e);
    }
}

/* ====== Saga for League List ======== */
function* fetchListPolicyLinkedToLeague(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/league_policies?league_id=${actions.payload}`);
        yield put(getListLeagueLinkedToPolicySuccess(res.data));
    } catch (e) {
        yield put(getListLeagueLinkedToPolicyFailure());
    }
}

/* ====== Saga for League List ======== */
function* fetchEvluationSettingList(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/evaluation_settings?league_id=${actions.payload}`);
        yield put(getEvaluationSettingSuccess(res.data));
    } catch (e) {
        yield put(getEvaluationSettingFailure());
    }
}

// /*Watch League  List*/
export function* watchLeagueListAPI() {
    yield takeEvery(GET_LEAGUES_LIST, fetchLeagueListRequest);
}

// /*Watch League  List*/
export function* watchListEvluation() {
    yield takeEvery(LIST_EVALUATION_SETTING, fetchEvluationSettingList);
}

//
/* Watch for Deleting League  */
export function* watchDeleteLeagueAPI() {
    yield takeEvery(DELETE_LEAGUE, deleteLeagueRequest);
}


/* Watch for Creating League  */
export function* watchCreateLeagueAPI() {
    yield takeEvery(CREATE_LEAGUE, CreateLeagueRequest);
}

export function* watchViewLeagueAPI() {
    yield takeEvery(VIEW_LEAGUE, ViewLeagueRequest);
}

// /*Watch for Deassign League team  */
export function* watchDeassignLeagueTeamAPI() {
    yield takeEvery(DEASSIGN_TEAM_FROM_LEAGUE, deassignLeagueTeamRequest);
}

export function* watchassignLeagueTeamAPI() {
    yield takeEvery(ASSIGN_TEAM_TO_LEAGUE, assignLeagueTeamRequest);
}
export function* watchCreateEvaluationAPI() {
    yield takeEvery(CREATE_EVALUATION, createEvaluation);
}

export function* watchgetTeamLinkedToLeague() {
    yield takeEvery(GET_TEAM_LINKED_TO_LEAGUE, fetchTeamLinkedToLeague);
}


// /*Watch for Viewing League season */
export function* watchViewLeagueSeasonAPI() {
    yield takeEvery(VIEW_SEASONS, ViewLeagueSeasonRequest);
}

// /*Watch for Viewing League season */
export function* watchCreateLeagueSeasonAPI() {
    yield takeEvery(CREATE_SEASON, CreateLeagueSeasonRequest);
}


/* Watch for assign policy to league League  */
export function* watchAssignPolicyToLeagueAPI() {
    yield takeEvery(ASSIGN_POLICY_TO_LEAGUE, AssignPolicyToLeagueRequest);
}
/* Watch for deassign policy from  League  */
export function* watchDeassignPolicyToLeagueAPI() {
    yield takeEvery(DEASSIGN_POLICY_FROM_LEAGUE, DeAssignPolicyToLeagueRequest);
}

/* Watch for deassign policy from  League  */
export function* watchGetLeagueDetailsByIdAPI() {
    yield takeEvery(GET_SINGLE_LEAGUE, getLeagueDetailsRequest);
}


/* Watch for List League List to policy */
export function* watchPolicyLinkedToLeagueAPI() {
    yield takeEvery(LIST_POLICY_LINKED_TO_LEAGUE, fetchListPolicyLinkedToLeague);
}
export default function* rootSaga() {
    yield all([
        watchLeagueListAPI(),
        watchDeleteLeagueAPI(),
        watchViewLeagueAPI(),
        watchDeassignLeagueTeamAPI(),
        watchViewLeagueSeasonAPI(),
        // watchCreateLeagueAPI()
        // watchViewLeagueAPI(),
        watchCreateLeagueAPI(),
        watchAssignPolicyToLeagueAPI(),
        watchDeassignPolicyToLeagueAPI(),
        watchCreateLeagueSeasonAPI(),
        watchassignLeagueTeamAPI(),
        watchGetLeagueDetailsByIdAPI(),
        watchCreateEvaluationAPI(),
        watchPolicyLinkedToLeagueAPI(),
        watchListEvluation(),
        watchgetTeamLinkedToLeague()
    ]);
}
