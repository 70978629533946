import {GET_BREADCRUM_DETAILS} from '../actions/constant';

const INIT_STATE = {
    breadCrumb:[],
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case GET_BREADCRUM_DETAILS:
            return { ...state, breadCrumb: action.payload };
        default:
            return state;
    }
};