/* Saga for Player Management */

import {all, takeEvery, put, call} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from "react-toastify";

import {
    GET_PLAYERS_LIST,
    DELETE_PLAYER, CREATE_PLAYER,
    // VIEW_PLAYER
} from '../actions/constant';

import {
    getPlayerListSuccess,
    getPlayerListFailure,
    getPlayerList, getBreadCrumb,
    //     viewPlayerSuccess,
//     viewPlayerFailure

} from "../actions";
import {apiUrl} from "../utils/helper";

/* ====== Saga for fetching  Player List ======== */
function* fetchPlayerListRequest(action) {
    let url;
    if(action.payload && action.payload.type === "filter") {
        url = `${apiUrl}/v1/users/pitchers?keywords${action.payload.keywords ? `=${action.payload.keywords}` : ''}&city${action.payload.city ? `=${action.payload.city}` : ''}&state${action.payload.state ? `=${action.payload.state}` : ''}&min_date_of_birth${action.payload.min_date_of_birth ? `=${action.payload.min_date_of_birth}` : ''}&max_date_of_birth${action.payload.max_date_of_birth ? `=${action.payload.max_date_of_birth}` : ''}&max_grade${action.payload.max_grade ? `=${action.payload.max_grade}` : ''}&min_grade${action.payload.min_grade ? `=${action.payload.min_grade}` : ''}&active${action.payload.active || action.payload.active === false ? `=${action.payload.active}` : ''}`;
    }
    else url =
        action.payload &&  action.payload.search !== " "
            ? `${apiUrl}/v1/users/pitchers?search=${action.payload.search}&remove_associations=true` :
            `${apiUrl}/v1/users/pitchers?remove_associations=true`;
    try {
        const res = yield  axios.get(url);
        yield  put(getPlayerListSuccess(res.data));
    } catch (e) {
        yield  put(getPlayerListFailure(e));
    }
}

/* ====== Saga for Creating  Player ======== */
function* createPlayerRequest(action) {
    const {obj,errorback,back,Id,Resubmit} = action.payload;
    let res;
    try {
        if (Id) {
            if(Resubmit) {
                res = yield axios.patch(`${apiUrl}/v1/users/pitchers/${Id}`, { "user": obj });
            }
            else{
                res = yield axios.patch(`${apiUrl}/v1/users/pitchers/${Id}`, { "user": obj, "check_potential_duplicates": "true" });   
            }
        }
        else if (Resubmit) { // Resubmit after Create a New Player 
            res = yield axios.post(`${apiUrl}/v1/users/pitchers`, { "user": obj });
        }
        else { // Deduplication Check
            res = yield axios.post(`${apiUrl}/v1/users/pitchers`, { "user": obj, "check_potential_duplicates": "true" });
        }
        if (res.status === 200) {
            if (res.data.potential_duplicate_users) {
                yield call(errorback(res.data, true)); // Match List Binding
            }
            else {
                yield put(getBreadCrumb([]));
                yield call(back);
                yield put(getPlayerList());
            }
        }
    } catch (e) {
        if (e.status === 422) {
            if (e.data.errors) {
                if (e.data.errors.email?.length > 0) {
                    toast.error("Email already exists");
                }
                else if (e.data.errors?.uuid.length > 0) {
                    toast.error("UUID already exists");
                }
            }
        }
        else {
            toast.dismiss();
        }
    }
}

/* ====== Saga for Deleting Player By ID  ======== */
function* deletePlayerRequest(actions) {
    const {Id} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/users/pitchers/${Id}`);
        yield put(getPlayerList());
    } catch (e) {
        console.log(e);
    }
}

/* Watch Player List */
export function* watchPlayerListAPI() {
    yield takeEvery(GET_PLAYERS_LIST, fetchPlayerListRequest);
}

/* Watch for Deleting Player */
export function* watchDeletePlayerAPI() {
    yield takeEvery(DELETE_PLAYER, deletePlayerRequest);
}

/* Watch for Deleting Player */
export function* watchCreatPlayerAPI() {
    yield takeEvery(CREATE_PLAYER, createPlayerRequest);
}

export default function* rootSaga() {
    yield all([
        watchPlayerListAPI(),
        watchDeletePlayerAPI(),
        watchCreatPlayerAPI()
    ]);
}
