/* Governing Entity Reducer */

import {
    GET_GOVERNING_ENTITY_LIST,
    GET_GOVERNING_ENTITY_LIST_SUCCESS,
    GET_GOVERNING_ENTITY_LIST_FAILURE,
} from "../actions/constant";


const INIT_STATE = {
    loading: false,
    governingEntityList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_GOVERNING_ENTITY_LIST:
            return {...state, loading: true};
        case GET_GOVERNING_ENTITY_LIST_SUCCESS:
            return {...state, loading: false, governingEntityList: action.payload};
        case GET_GOVERNING_ENTITY_LIST_FAILURE:
            return {...state, loading: false, governingEntityList: action.payload};

        default:
            return state;
    }
};
