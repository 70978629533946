import {
    GET_GAME_LIST,
    GET_GAME_LIST_SUCCESS,
    GET_GAME_LIST_FAILURE,

    CREATE_OR_EDIT_GAME,

    EMPTY_GAME_LIST
} from './constant';

export const getGameList = (payload) => ({
   type: GET_GAME_LIST,
    payload
});

export const getGameListSuccess = (payload) => ({
    type: GET_GAME_LIST_SUCCESS,
    payload
});

export const getGameListFailure = (payload) => ({
   type: GET_GAME_LIST_FAILURE,
   payload
});

export const createOrEditGame = payload => ({
   type: CREATE_OR_EDIT_GAME,
   payload
});

export const emptyGameList = () => ({
    type: EMPTY_GAME_LIST
});