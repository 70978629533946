import axios from 'axios';
import {toast} from "react-toastify";


export function setAuthToken(token) {
    axios.defaults.headers.common.Authorization = '';
    delete axios.defaults.headers.common.Authorization;

    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    return true;
}

axios.interceptors.response.use((response) => {
    return response;
},  (error) => {
    if (error.response) {
        if(error.response.status === 422){
            if(error.response.data.error !== undefined)
            toast.error(error.response.data.error);
        }

    }

    return Promise.reject(error.response);
});