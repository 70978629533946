import {
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE
} from "./constant";

export const loginUser = payload => ({
  type: LOGIN_USER,
  payload
});

export const loginUserSuccess = payload => ({
  type: LOGIN_USER_SUCCESS,
  payload
});

export const loginUserFailure = payload => ({
  type: LOGIN_USER_FAILURE,
  payload
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const getUserDetails = () => ({
  type: GET_USER_DETAILS
});

export const getUserDetailsSuccess = payload => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload
});

export const getUserDetailsFail = payload => ({
  type: GET_USER_DETAILS_FAILURE,
  payload
});
