/* League Management Reducer */

import {
    GET_PLAYERS_LIST,
    GET_PLAYERS_LIST_SUCCESS,
    GET_PLAYERS_LIST_FAILURE,

    VIEW_PLAYER,
    VIEW_PLAYER_SUCCESS,
    VIEW_PLAYER_FAILURE

} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    playerList:[],
    viewPlayerByID:null,
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {

        case GET_PLAYERS_LIST:
            return { ...state, loading: true };
        case GET_PLAYERS_LIST_SUCCESS:
            return { ...state, loading: false, playerList: action.payload };
        case GET_PLAYERS_LIST_FAILURE:
            return { ...state, loading: false, playerList: action.payload };

        case VIEW_PLAYER:
            return { ...state, loading: false };
        case VIEW_PLAYER_SUCCESS:
            return { ...state, loading: false, viewPlayerByID: action.payload };
        case VIEW_PLAYER_FAILURE:
            return { ...state, loading: false, viewPlayerByID: action.payload };
        default:
            return state;
    }
};
