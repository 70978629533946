/* Saga for Counter Management */

import {all, takeEvery, put, call} from 'redux-saga/effects';
import {toast} from "react-toastify";
import axios from 'axios';

import {

    GET_COUNTER_LIST,
    DELETE_COUNTER, CREATE_COUNTER,
    // VIEW_COUNTER
} from '../actions/constant';
import {
    getCounterListSuccess,
    getCounterListFailure,

    getCounterList, getBreadCrumb,
    getCounterLinkedToTeam

    // viewCounterSuccess,
    // viewCounterFailure,

} from "../actions";
import {apiUrl} from "../utils/helper";



// eslint-disable-next-line require-yield,no-unused-vars

/* ====== Saga for fetching  Counter List ======== */
function* fetchCounterListRequest(actions) {
    let url;
    if(actions.payload && actions.payload.type === "filter") {
        url = `${apiUrl}/v1/users/counters?keywords${actions.payload.keywords ? `=${actions.payload.keywords}` : ''}&city${actions.payload.city ? `=${actions.payload.city}` : ''}&state${actions.payload.state ? `=${actions.payload.state}` : ''}&active${actions.payload.active || actions.payload.active === false ? `=${actions.payload.active}` : ''}`;
    }
    else url = actions.payload ? `${apiUrl}/v1/users/counters?search=${actions.payload.search}` : `${apiUrl}/v1/users/counters`;
    try {
        const res = yield axios.get(url);
        yield put(getCounterListSuccess(res.data));
    } catch (e) {
        yield put(getCounterListFailure(e));
    }
}

/* ====== Saga for Creating counter  ======== */
function* createCounterRequest(actions) {
    const {data, back, counterId, id} = actions.payload;
    try {
        if (counterId) yield  axios.patch(`${apiUrl}/v1/users/counters/${counterId}`, data);
        else yield  axios.post(`${apiUrl}/v1/users/counters`, data);
        yield put(getBreadCrumb([]));
        yield  call(back);
        yield  put(getCounterList());
        if(id) put(getCounterLinkedToTeam(id));
    } catch (e) {
        if(e.data.errors.email) toast.error('Email has been already taken');
        if(e.data.errors.uuid) toast.error('UUID has been already taken');
    }
}
/* ====== Saga for Deleting Counter By ID  ======== */
function* deleteCounterRequest(actions) {
    const {Id} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/users/counters/${Id}`);
        yield  put(getCounterList());
    } catch (e) {
        console.log(e);
    }
}

/* Watch Counter List */
export function* watchCounterListAPI() {
    yield takeEvery(GET_COUNTER_LIST, fetchCounterListRequest);
}

/* Watch for Deleting Counter */
export function* watchDeleteCounterAPI() {
    yield takeEvery(DELETE_COUNTER, deleteCounterRequest);
}

/* Watch for Creating Counter */
export function* watchCreateCounterAPI() {
    yield takeEvery(CREATE_COUNTER, createCounterRequest);
}

export default function* rootSaga() {
    yield all([
        watchCounterListAPI(),
        watchDeleteCounterAPI(),
        watchCreateCounterAPI()
    ]);
}
