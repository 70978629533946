// ========================Login=============================
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

/* ====================Governing Entity Management Types======================= */

/* =========Governing=========== */

// List of  Governing Entity
export const GET_GOVERNING_ENTITY_LIST = 'GET_GOVERNING_ENTITY_LIST';
export const GET_GOVERNING_ENTITY_LIST_SUCCESS = 'GET_GOVERNING_ENTITY_LIST_SUCCESS';
export const GET_GOVERNING_ENTITY_LIST_FAILURE = 'GET_GOVERNING_ENTITY_LIST_FAILURE';

// Delete Governing Entity By ID
export const DELETE_GOVERNING_ENTITY = 'DELETE_GOVERNING_ENTITY';

// View Governing Entity By ID
export const VIEW_GOVERNING_ENTITY = 'VIEW_GOVERNING_ENTITY';
export const VIEW_GOVERNING_ENTITY_SUCCESS = 'VIEW_GOVERNING_ENTITY_SUCCESS';
export const VIEW_GOVERNING_ENTITY_FAILURE = 'VIEW_GOVERNING_ENTITY_FAILURE';

// Create Governing Entity
export const CREATE_GOVERNING_ENTITY = 'CREATE_GOVERNING_ENTITY';

// Get Governing Entity Data with ID
export const GET_GOV_ENTITY_DATA_BY_ID = 'GET_GOV_ENTITY_DATA_BY_ID';
export const GET_GOV_ENTITY_DATA_BY_ID_SUCCESS = 'GET_GOV_ENTITY_DATA_BY_ID_SUCCESS';
export const GET_GOV_ENTITY_DATA_BY_ID_FAILURE = 'GET_GOV_ENTITY_DATA_BY_ID_FAILURE';

/* ======== Governing Policies Management ============ */

// List of Policies in Governing Entity
export const GET_POLICIES_LIST = 'GET_POLICIES_LIST';
export const GET_POLICIES_LIST_SUCCESS = 'GET_POLICIES_LIST_SUCCESS';
export const GET_POLICIES_LIST_FAILURE = 'GET_POLICIES_LIST_FAILURE';

// Creating Policy in Governing Entity
export const CREATE_POLICY = 'CREATE_POLICY';

// View Policy in Governing Entity
export const VIEW_POLICY = 'VIEW_POLICY';
export const VIEW_POLICY_SUCCESS = 'VIEW_POLICY_SUCCESS';
export const VIEW_POLICY_FAILURE = 'VIEW_POLICY_FAILURE';

// Delete Policy in Governing Entity
export const DELETE_POLICY = 'DELETE_POLICY';

/* ======== Governing Policies Management ============ */

// List of Policies in Governing Entity
export const GET_LEVELS_LIST = 'GET_LEVELS_LIST';
export const GET_LEVELS_LIST_SUCCESS = 'GET_LEVELS_LIST_SUCCESS';
export const GET_LEVELS_LIST_FAILURE = 'GET_LEVELS_LIST_FAILURE';

// Creating Level in Governing Entity
export const CREATE_LEVEL = 'CREATE_LEVEL';

// View Policy in Governing Entity
export const VIEW_LEVEL = 'VIEW_LEVEL';
export const VIEW_LEVEL_SUCCESS = 'VIEW_LEVEL_SUCCESS';
export const VIEW_LEVEL_FAILURE = 'VIEW_LEVEL_FAILURE';

// Delete Policy in Governing Entity
export const DELETE_LEVEL = 'DELETE_LEVEL';

/* ====================League Management Types======================= */

// Get League Details BY ID
export const GET_SINGLE_LEAGUE = 'GET_SINGLE_LEAGUE';
export const GET_SINGLE_LEAGUE_FAILURE = 'GET_SINGLE_LEAGUE_FAILURE';
export const GET_SINGLE_LEAGUE_SUCCESS = 'GET_SINGLE_LEAGUE_SUCCESS';

// List of Leagues
export const GET_LEAGUES_LIST = 'GET_LEAGUES_LIST';
export const GET_LEAGUES_LIST_SUCCESS = 'GET_LEAGUES_LIST_SUCCESS';
export const GET_LEAGUES_LIST_FAILURE = 'GET_LEAGUES_LIST_FAILURE';

// Delete League By ID
export const DELETE_LEAGUE = 'DELETE_LEAGUE';

// View League By ID
export const VIEW_LEAGUE = 'VIEW_LEAGUE';
export const VIEW_LEAGUE_SUCCESS = 'VIEW_LEAGUE_SUCCESS';
export const VIEW_LEAGUE_FAILURE = 'VIEW_LEAGUE_FAILURE';

// List Counter linked to team
export const GET_TEAM_LINKED_TO_LEAGUE ="GET_TEAM_LINKED_TO_LEAGUE ";
export const GET_TEAM_LINKED_TO_LEAGUE_SUCCESS ="GET_TEAM_LINKED_TO_LEAGUE_SUCCESS";
export const GET_TEAM_LINKED_TO_LEAGUE_FAILURE="GET_TEAM_LINKED_TO_LEAGUE_FAILURE";

// Assign Deassign Team to League
export const ASSIGN_TEAM_TO_LEAGUE = 'ASSIGN_TEAM_TO_LEAGUE';
export const DEASSIGN_TEAM_FROM_LEAGUE = 'DEASSIGN_TEAM_FROM_LEAGUE';

// League Seasons
export const VIEW_SEASONS = 'VIEW_SEASONS';
export const VIEW_SEASONS_SUCCESS = 'VIEW_SEASONS_SUCCESS';
export const VIEW_SEASONS_FAILURE = 'VIEW_SEASONS_FAILURE';

export const CREATE_SEASON = 'CREATE_SEASON';

// Create League
export const CREATE_LEAGUE = 'CREATE_LEAGUE';

// Create Evaluation
export const CREATE_EVALUATION = 'CREATE_EVALUATION';

// Assign-DeAssign policy to league

export const ASSIGN_POLICY_TO_LEAGUE = 'ASSIGN_POLICY_TO_LEAGUE';
export const DEASSIGN_POLICY_FROM_LEAGUE = 'DEASSIGN_POLICY_FROM_LEAGUE';

// List policy linked to the list
export const LIST_POLICY_LINKED_TO_LEAGUE = 'LIST_POLICY_LINKED_TO_LEAGUE';
export const LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS = 'LIST_POLICY_LINKED_TO_LEAGUE_SUCCESS';
export const LIST_POLICY_LINKED_TO_LEAGUE_FAILURE = 'LIST_POLICY_LINKED_TO_LEAGUE_FAILURE';

// List Evaluation settings

export const LIST_EVALUATION_SETTING = "LIST_EVALUATION_SETTING";
export const LIST_EVALUATION_SETTING_SUCCESS = "LIST_EVALUATION_SETTING_SUCCESS";
export const LIST_EVALUATION_SETTING_FAILURE = "LIST_EVALUATION_SETTING_FAILURE";
/* ====================Team Management Types======================= */

// List of Team
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const GET_TEAM_LIST_SUCCESS = 'GET_TEAM_LIST_SUCCESS';
export const GET_TEAM_LIST_FAILURE = 'GET_TEAM_LIST_FAILURE';

// Delete Team By ID
export const DELETE_TEAM = 'DELETE_TEAM';

// View  Team Player By ID
export const VIEW_TEAM_PLAYER_LIST = 'VIEW_TEAM_PLAYER_LIST';
export const VIEW_TEAM_PLAYER_LIST_SUCCESS = 'VIEW_TEAM_PLAYER_LIST_SUCCESS';
export const VIEW_TEAM_PLAYER_LIST_FAILURE = 'VIEW_TEAM_PLAYER_LIST_FAILURE';

// View Team Coach By ID
export  const VIEW_TEAM_COACH_LIST = 'VIEW_TEAM_COACH_LIST';
export  const VIEW_TEAM_COACH_LIST_SUCEESS = 'VIEW_TEAM_COACH_LIST_SUCEESS';
export  const VIEW_TEAM_COACH_LIST_FAILURE = 'VIEW_TEAM_COACH_LIST_FAILURE';

// View Team CounteR By ID
export  const VIEW_TEAM_COUNTER_LIST = 'VIEW_TEAM_COUNTER_LIST';
export  const VIEW_TEAM_COUNTER_LIST_SUCCESS = 'VIEW_TEAM_COUNTER_LIST_SUCCESS';
export  const VIEW_TEAM_COUNTER_LIST_FAILURE = 'VIEW_TEAM_COUNTER_LIST_FAILURE';

// Create team
export const CREATE_TEAM = 'CREATE_TEAM';

// Assign player to team
export const ASSIGN_PLAYER_TO_TEAM = "ASSIGN_PLAYER_TO_TEAM";

// Assign coach to team
export const ASSIGN_COACH_TO_TEAM = "ASSIGN_COACH_TO_TEAM";

// Assign Counter to team
export const ASSIGN_COUNTER_TO_TEAM = "ASSIGN_COUNTER_TO_TEAM";

// Show Single Team
export const SHOW_SINGLE_TEAM ="SHOW_SINGLE_TEAM";
export const SHOW_SINGLE_TEAM_SUCCESS ="SHOW_SINGLE_TEAM_SUCCESS";
export const SHOW_SINGLE_TEAM_FAILURE ="SHOW_SINGLE_TEAM_FAILURE";

// List Player linked to team
export const LIST_PLAYER_LINKED_TO_TEAM ="LIST_PLAYER_LINKED_TO_TEAM";
export const LIST_PLAYER_LINKED_TO_TEAM_SUCCESS ="LIST_PLAYER_LINKED_TO_TEAM_SUCCESS";
export const LIST_PLAYER_LINKED_TO_TEAM_FAILURE ="LIST_PLAYER_LINKED_TO_TEAM_FAILURE";

// List Coach linked to team
export const LIST_COACH_LINKED_TO_TEAM ="LIST_COACH_LINKED_TO_TEAM ";
export const LIST_COACH_LINKED_TO_TEAM_SUCCESS ="LIST_COACH_LINKED_TO_TEAM_SUCCESS";
export const LIST_COACH_LINKED_TO_TEAM_FAILURE="LIST_COACH_LINKED_TO_TEAM_FAILURE";

// De-assign coach from league
export const DEASSIGN_COACH_FROM_TEAM ="DEASSIGN_COACH_FROM_TEAM ";

// List Counter linked to team
export const LIST_COUNTER_LINKED_TO_TEAM ="LIST_COUNTER_LINKED_TO_TEAM ";
export const LIST_COUNTER_LINKED_TO_TEAM_SUCCESS ="LIST_COUNTER_LINKED_TO_TEAM_SUCCESS";
export const LIST_COUNTER_LINKED_TO_TEAM_FAILURE="LIST_COUNTER_LINKED_TO_TEAM_FAILURE";

// De-assign coach from league
export const DEASSIGN_COUNTER_FROM_TEAM ="DEASSIGN_COUNTER_FROM_TEAM ";

// De-assign Player from Team
export const DEASSIGN_PLAYER_FROM_TEAM ="DEASSIGN_PLAYER_FROM_TEAM ";

// De-assign coach from league
export const CREATE_NON_LEAGUE ="CREATE_NON_LEAGUE";

// List Team Without Serilization

export const LIST_TEAM_WITHOUT_SERIALIZATION ="LIST_TEAM_WITHOUT_SERIALIZATION";
export const LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS ="LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS";
export const LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE="LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE";

/* ====================User Management Types======================= */

/* =====Coach Management===== */
// List of Coach
export const GET_COACH_LIST = 'GET_COACH_LIST';
export const GET_COACH_LIST_SUCCESS = 'GET_COACH_LIST_SUCCESS';
export const GET_COACH_LIST_FAILURE = 'GET_COACH_LIST_FAILURE';

// Delete Coach By ID
export const DELETE_COACH = 'DELETE_COACH';

// View Coach By ID
export const VIEW_COACH = 'VIEW_COACH';
export const VIEW_COACH_SUCCESS = 'VIEW_COACH_SUCCESS';
export const VIEW_COACH_FAILURE = 'VIEW_COACH_FAILURE';

// Create Coach
export const CREATE_COACH = 'CREATE_COACH';

/* =====Counter Management===== */
// List of Counter
export const GET_COUNTER_LIST = 'GET_COUNTER_LIST';
export const GET_COUNTER_LIST_SUCCESS = 'GET_COUNTER_LIST_SUCCESS';
export const GET_COUNTER_LIST_FAILURE = 'GET_COUNTER_LIST_FAILURE';

// Delete Counter By ID
export const DELETE_COUNTER = 'DELETE_COUNTER';

// View Coach By ID
export const VIEW_COUNTER = 'VIEW_COUNTER';
export const VIEW_COUNTER_SUCCESS = 'VIEW_COUNTER_SUCCESS';
export const VIEW_COUNTER_FAILURE = 'VIEW_COUNTER_FAILURE';

// Create counter
export const CREATE_COUNTER = 'CREATE_COUNTER';


/* =====Player Management===== */
// List of Player
export const GET_PLAYERS_LIST = 'GET_PLAYERS_LIST';
export const GET_PLAYERS_LIST_SUCCESS = 'GET_PLAYERS_LIST_SUCCESS';
export const GET_PLAYERS_LIST_FAILURE = 'GET_PLAYERS_LIST_FAILURE';

// Delete Player By ID
export const DELETE_PLAYER = 'DELETE_PLAYER';

// View Player By ID
export const VIEW_PLAYER = 'VIEW_PLAYER';
export const VIEW_PLAYER_SUCCESS = 'VIEW_PLAYER_SUCCESS';
export const VIEW_PLAYER_FAILURE = 'VIEW_PLAYER_FAILURE';

// Create Player
export const CREATE_PLAYER ="CREATE_PLAYER";

// BreadCrumb Dynamic

export const GET_BREADCRUM_DETAILS = 'GET_BREADCRUM_DETAILS';

// Team game list

export const GET_TEAM_GAME_LIST = 'GET_TEAM_GAME_LIST';
export const GET_TEAM_GAME_LIST_SUCCESS = 'GET_TEAM_GAME_LIST_SUCCESS';
export const GET_TEAM_GAME_LIST_FAILURE = 'GET_TEAM_GAME_LIST_FAILURE';


// All Season
export const VIEW_ALL_SEASONS = 'VIEW_ALL_SEASONS';
export const VIEW_ALL_SEASONS_SUCCESS = 'VIEW_ALL_SEASONS_SUCCESS';
export const VIEW_ALL_SEASONS_FAILURE = 'VIEW_ALL_SEASONS_FAILURE';

// Game Mangament Constant
export const GET_GAME_LIST = 'GET_GAME_LIST';
export const GET_GAME_LIST_SUCCESS = 'GET_GAME_LIST_SUCCESS';
export const GET_GAME_LIST_FAILURE = 'GET_GAME_LIST_FAILURE';

export const EMPTY_GAME_LIST = 'EMPTY_GAME_LIST';

export const CREATE_OR_EDIT_GAME = 'CREATE_OR_EDIT_GAME';

// System Configuration
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAILURE = 'GET_NOTIFICATION_LIST_FAILURE';

// notification update
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';


//  gameEndHour list
export const GAME_END_HOUR_LIST = 'GAME_END_HOUR_LIST';
export const GAME_END_HOUR_SUCCESS = 'GAME_END_HOUR_SUCCESS';
export const GAME_END_HOUR_FALIURE = 'GAME_END_HOUR_FALIURE';

//  gameEndHour update
export const UPDATE_GAME_END_HOUR = 'UPDATE_GAME_END_HOUR';
export const UPDATE_GAME_END_HOUR_SUCCESS = 'UPDATE_GAME_END_HOUR_SUCCESS';
export const UPDATE_GAME_END_HOUR_FALIURE = 'UPDATE_GAME_END_HOUR_FALIURE';
