/* Actions of Governing Entity */

import {
    GET_GOVERNING_ENTITY_LIST,
    GET_GOVERNING_ENTITY_LIST_SUCCESS,
    GET_GOVERNING_ENTITY_LIST_FAILURE,

    DELETE_GOVERNING_ENTITY,

    VIEW_GOVERNING_ENTITY,
    VIEW_GOVERNING_ENTITY_SUCCESS,
    VIEW_GOVERNING_ENTITY_FAILURE,

    CREATE_GOVERNING_ENTITY,

    CREATE_POLICY,

    GET_POLICIES_LIST,
    GET_POLICIES_LIST_SUCCESS,
    GET_POLICIES_LIST_FAILURE,

    DELETE_POLICY,

    VIEW_POLICY,
    VIEW_POLICY_FAILURE,
    VIEW_POLICY_SUCCESS,

    CREATE_LEVEL,

    GET_LEVELS_LIST,
    GET_LEVELS_LIST_SUCCESS,
    GET_LEVELS_LIST_FAILURE,

    VIEW_LEVEL,
    VIEW_LEVEL_SUCCESS,
    VIEW_LEVEL_FAILURE,

    DELETE_LEVEL,

    GET_GOV_ENTITY_DATA_BY_ID,
    GET_GOV_ENTITY_DATA_BY_ID_SUCCESS,
    GET_GOV_ENTITY_DATA_BY_ID_FAILURE

} from "./constant";

/* ======== List of  Governing Entities Actions ========== */
export const getGoverningEntityList = () => ({
    type: GET_GOVERNING_ENTITY_LIST,
});

export const getGoverningEntityListSuccess = (payload) => ({
    type: GET_GOVERNING_ENTITY_LIST_SUCCESS,
    payload
});

export const getGoverningEntityListFailure = (payload) => ({
    type: GET_GOVERNING_ENTITY_LIST_FAILURE,
    payload
});

/* ======== Delete Governing Entity By ID Actions =========== */
export const deleteGoverningEntity = (payload) => ({
    type: DELETE_GOVERNING_ENTITY,
    payload
});


/* ======== View  Governing Entity By ID Actions ========== */
export const viewGoverningEntity = (payload) => ({
    type: VIEW_GOVERNING_ENTITY,
    payload
});

export const viewGoverningEntitySuccess = (payload) => ({
    type: VIEW_GOVERNING_ENTITY_SUCCESS,
    payload
});

export const viewGoverningEntityFailure = (payload) => ({
    type: VIEW_GOVERNING_ENTITY_FAILURE,
    payload
});

/* ======== Create  Governing Entity By ID Actions ====== */
export const createGoverningEntity = (payload) => ({
    type: CREATE_GOVERNING_ENTITY,
    payload
});

/* ======== Get Policies List Actions ========== */
export const getPoliciesList = (payload) => ({
    type: GET_POLICIES_LIST,
    payload
});

export const getPoliciesListSuccess = (payload) => ({
    type: GET_POLICIES_LIST_SUCCESS,
    payload
});

export const getPoliciesListFailure = (payload) => ({
    type: GET_POLICIES_LIST_FAILURE,
    payload
});

/* ======== Create Policy Actions ========== */
export const createPolicy = (payload) => ({
    type: CREATE_POLICY,
    payload,
});


/* ======== View Policy Actions ========== */
export const viewPolicy = () => ({
    type: VIEW_POLICY,
});

export const viewPolicySuccess = (payload) => ({
    type: VIEW_POLICY_SUCCESS,
    payload
});

export const viewPolicyFailure = (payload) => ({
    type: VIEW_POLICY_FAILURE,
    payload
});

/* ======== Update Policy Actions ========== */
export const deletePolicy = (payload) => ({
    type: DELETE_POLICY,
    payload
});

/* ======== Get Levels List Actions ========== */
export const getLevelsList = (payload) => ({
    type: GET_LEVELS_LIST,
    payload
});

export const getLevelsListSuccess = (payload) => ({
    type: GET_LEVELS_LIST_SUCCESS,
    payload
});

export const getLevelsListFailure = (payload) => ({
    type: GET_LEVELS_LIST_FAILURE,
    payload
});

/* ======== Create Level Actions ========== */
export const createLevels = (payload) => ({
    type: CREATE_LEVEL,
    payload
});

/* ======== View Level Actions ========== */
export const viewLevels = () => ({
    type: VIEW_LEVEL,
});

export const viewLevelsSuccess = (payload) => ({
    type: VIEW_LEVEL_SUCCESS,
    payload
});

export const viewLevelsFailure = (payload) => ({
    type: VIEW_LEVEL_FAILURE,
    payload
});

/* ======== Delete Level Actions ========== */
export const deleteLevels = (payload) => ({
    type: DELETE_LEVEL,
    payload
});

/* Get Governing entity data */
export const getGovEntityDataById = payload => ({
    type: GET_GOV_ENTITY_DATA_BY_ID,
    payload
});

export const getGovEntityDataByIdSuccess = payload => ({
    type: GET_GOV_ENTITY_DATA_BY_ID_SUCCESS,
    payload
});

export const getGovEntityDataByIdFailure = payload => ({
    type: GET_GOV_ENTITY_DATA_BY_ID_FAILURE,
    payload
});