import {all, takeEvery, put} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from "react-toastify";

import { GET_GAME_LIST, CREATE_OR_EDIT_GAME } from '../actions/constant';
import {getGameListSuccess, getGameListFailure, createOrEditGame} from '../actions';
import {apiUrl} from "../utils/helper";

function* getGameListRequest(actions) {
    let url;
    if(actions.payload && actions.payload.type === "filter") {
        url = `${apiUrl}/v1/games?keywords${actions.payload.keywords ? `=${actions.payload.keywords}` : ''}&city${actions.payload.city ? `=${actions.payload.city}` : ''}&state${actions.payload.state ? `=${actions.payload.state}` : ''}&min_date${actions.payload.min_date ? `=${actions.payload.min_date}` : ''}&max_date${actions.payload.max_date ? `=${actions.payload.max_date}` : ''}&governing_entity_id${actions.payload.governing_entity_id ? `=${actions.payload.governing_entity_id}` : ''}`;
    } else url = actions.payload ? `${apiUrl}/v1/games?last_id=${actions.payload.last_id}` : `${apiUrl}/v1/games`;
    try {
        const res = yield axios.get(url);
        yield put(getGameListSuccess(res.data));
    } catch (e) {
        yield put(getGameListFailure(e));
    }
}

function* createOrEditGameRequest(action) {
    try {
        if (action.payload) {
            const res = yield action.payload.game_id && action.payload.game_id ? axios.patch(`${apiUrl}/v1/games/${action.payload.game_id && action.payload.game_id}`, action.payload.data) : axios.post(`${apiUrl}/v1/games`, action.payload.data);
            if (res.status === 200) {
                yield axios.get(`${apiUrl}/v1/games`).then(async (responce) => {
                    await getGameListSuccess(responce.data);
                    await action.payload.back();
                    await put(createOrEditGame());
                });
            }
        }
    } catch (e) {
        if(e.status === 422) {
            toast.error(JSON.stringify(e.data.errors));
            yield put(createOrEditGame());
        }
    }
}

export function* watchGameListAPI() {
 yield takeEvery(GET_GAME_LIST, getGameListRequest);
}

export function* watchCreateOrEditGameAPI() {
    yield takeEvery(CREATE_OR_EDIT_GAME, createOrEditGameRequest);
}

export default function* rootSaga() {
    yield all([
        watchGameListAPI(),
        watchCreateOrEditGameAPI()
    ]);
}