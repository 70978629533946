/* Saga for Coach Management */
/* Global imports */
import {all, takeEvery, put, call} from 'redux-saga/effects';
import axios from 'axios';

/* Component specific imports */
import {toast} from "react-toastify";
import {
    GET_COACH_LIST,
    DELETE_COACH,
    // VIEW_COACH,
    CREATE_COACH
} from '../actions/constant';

import {
    getCoachListSuccess,
    getCoachListFailure,
    getCoachList, getBreadCrumb,
    // viewCoachSuccess,
    // viewCoachFailure
} from "../actions";

import {apiUrl} from "../utils/helper";

/* ====== Saga for fetching  Coach List ======== */
function* fetchCoachListRequest(actions) {
    let url;
    if(actions.payload && actions.payload.type === "filter") {
        url = `${apiUrl}/v1/users/coaches?keywords${actions.payload.keywords ? `=${actions.payload.keywords}` : ''}&city${actions.payload.city ? `=${actions.payload.city}` : ''}&state${actions.payload.state ? `=${actions.payload.state}` : ''}&active${actions.payload.active || actions.payload.active === false ? `=${actions.payload.active}` : ''}`;
    }
    else url = actions.payload ? `${apiUrl}/v1/users/coaches?search=${actions.payload.search}` : `${apiUrl}/v1/users/coaches`;
    try {
        const res = yield axios.get(url);
        yield put(getCoachListSuccess(res.data));

    } catch (e) {
        yield put(getCoachListFailure(e));
    }
}

/* ====== Saga for Deleting Coach By ID  ======== */
function* deleteCoachRequest(actions) {
    const {Id} = actions.payload;
    try {
        yield  axios.delete(`${apiUrl}/v1/users/coaches/${Id}`);
        yield put(getCoachList());
    } catch (e) {
        console.log(e);
    }
}

/* ====== Saga for Creating Coach  ======== */
function* createCoachRequest(actions) {
    const {data, back, coachId} = actions.payload;
    try {
        if (coachId)
        {
            const res = yield axios.patch(`${apiUrl}/v1/users/coaches/${coachId}`, data);
            if(res.status === 200) {
                yield put(getCoachList());
                yield put(getBreadCrumb([]));
                yield  call(back);
            }
        }
        else{
            const res = yield  axios.post(`${apiUrl}/v1/users/coaches`, data);
            if(res.status === 200) {
                yield put(getCoachList());
                yield put(getBreadCrumb([]));
                yield  call(back);
            }
        }
    } catch (e) {
        if(e.data.errors.uuid) toast.error("Please enter Unique UUID.");
        if(e.data.errors.email) toast.error("Email hase already been taken");
    }
}

/* Watch Coach List */
export function* watchCoachListAPI() {
    yield takeEvery(GET_COACH_LIST, fetchCoachListRequest);
}

/* Watch for Deleting Coach */
export function* watchDeleteCoachAPI() {
    yield takeEvery(DELETE_COACH, deleteCoachRequest);
}

/* Watch for Creating Coach */
export function* watchCreateCoachAPI() {
    yield takeEvery(CREATE_COACH, createCoachRequest);
}

export default function* rootSaga() {
    yield all([
        watchCoachListAPI(),
        watchDeleteCoachAPI(),
        watchCreateCoachAPI()
    ]);
}
