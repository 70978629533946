/* Team Management Reducer */


import {
    GET_TEAM_LIST,
    GET_TEAM_LIST_SUCCESS,
    GET_TEAM_LIST_FAILURE,

    VIEW_TEAM_PLAYER_LIST,
    VIEW_TEAM_PLAYER_LIST_FAILURE,
    VIEW_TEAM_PLAYER_LIST_SUCCESS,

    VIEW_TEAM_COACH_LIST,
    VIEW_TEAM_COACH_LIST_SUCEESS,
    VIEW_TEAM_COACH_LIST_FAILURE,

    VIEW_TEAM_COUNTER_LIST,
    VIEW_TEAM_COUNTER_LIST_SUCCESS,
    VIEW_TEAM_COUNTER_LIST_FAILURE,

    SHOW_SINGLE_TEAM,
    SHOW_SINGLE_TEAM_FAILURE,
    SHOW_SINGLE_TEAM_SUCCESS,

    GET_TEAM_GAME_LIST,
    GET_TEAM_GAME_LIST_SUCCESS,
    GET_TEAM_GAME_LIST_FAILURE,

    VIEW_ALL_SEASONS,
    VIEW_ALL_SEASONS_FAILURE,
    VIEW_ALL_SEASONS_SUCCESS,

    LIST_PLAYER_LINKED_TO_TEAM,
    LIST_PLAYER_LINKED_TO_TEAM_SUCCESS,
    LIST_PLAYER_LINKED_TO_TEAM_FAILURE,

    LIST_COACH_LINKED_TO_TEAM,
    LIST_COACH_LINKED_TO_TEAM_SUCCESS,
    LIST_COACH_LINKED_TO_TEAM_FAILURE,

    LIST_COUNTER_LINKED_TO_TEAM,
    LIST_COUNTER_LINKED_TO_TEAM_SUCCESS,
    LIST_COUNTER_LINKED_TO_TEAM_FAILURE,
    LIST_TEAM_WITHOUT_SERIALIZATION,
    LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS,
    LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE


} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    teamList:[],
    viewPlayerTeamListById:[],
    viewCoachTeamListById:[],
    leagueDetails: {},
    viewCounterTeamById:[],
    showSingleTeamById:{},
    teamGameList: [],
    allSeasons: [],
    playerLinkedToTeam:[],
    coachLinkedToTeam:[],
    counterLinkedToTeam:[],
    teamWithoutSerializationData:[]
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {

        case GET_TEAM_LIST:
            return { ...state, loading: true };
        case GET_TEAM_LIST_SUCCESS:
            return { ...state, loading: false, teamList: action.payload };
        case GET_TEAM_LIST_FAILURE:
            return { ...state, loading: false, teamList: action.payload };

        case LIST_TEAM_WITHOUT_SERIALIZATION:
            return { ...state, loading: true };
        case LIST_TEAM_WITHOUT_SERIALIZATION_SUCCESS:
            return { ...state, loading: false, teamWithoutSerializationData: action.payload };
        case LIST_TEAM_WITHOUT_SERIALIZATION_FAILURE:
            return { ...state, loading: false, teamWithoutSerializationData: action.payload };


        case VIEW_TEAM_PLAYER_LIST:
            return { ...state, loading: false };
        case VIEW_TEAM_PLAYER_LIST_SUCCESS:
            return { ...state, loading: false, viewPlayerTeamListById: action.payload };
        case VIEW_TEAM_PLAYER_LIST_FAILURE:
            return { ...state, loading: false, viewPlayerTeamListById: action.payload };

        case VIEW_TEAM_COACH_LIST:
            return { ...state, loading: false };
        case VIEW_TEAM_COACH_LIST_SUCEESS:
            return { ...state, loading: false, viewCoachTeamListById: action.payload };
        case VIEW_TEAM_COACH_LIST_FAILURE:
            return { ...state, loading: false, viewCoachTeamListById: action.payload };

        case VIEW_TEAM_COUNTER_LIST:
            return { ...state, loading: false };
        case VIEW_TEAM_COUNTER_LIST_SUCCESS:
            return { ...state, loading: false, viewCounterTeamById: action.payload };
        case VIEW_TEAM_COUNTER_LIST_FAILURE:
            return { ...state, loading: false, viewCounterTeamById: action.payload };

        case SHOW_SINGLE_TEAM:
            return { ...state, loading: false };
        case SHOW_SINGLE_TEAM_SUCCESS:
            return { ...state, loading: false, showSingleTeamById: action.payload };
        case SHOW_SINGLE_TEAM_FAILURE:
            return { ...state, loading: false, showSingleTeamById: action.payload };

        case GET_TEAM_GAME_LIST:
            return { ...state, loading: true };
        case GET_TEAM_GAME_LIST_SUCCESS:
            return { ...state, loading: false, teamGameList: action.payload };
        case GET_TEAM_GAME_LIST_FAILURE:
            return { ...state, loading: false, teamGameList: action.payload };

        case VIEW_ALL_SEASONS:
            return { ...state, loading: false };
        case VIEW_ALL_SEASONS_SUCCESS:
            return { ...state, loading: false, allSeasons: action.payload };
        case VIEW_ALL_SEASONS_FAILURE:
            return { ...state, loading: false, allSeasons: action.payload };

        case LIST_PLAYER_LINKED_TO_TEAM:
            return { ...state, loading: false };
        case LIST_PLAYER_LINKED_TO_TEAM_SUCCESS:
            return { ...state, loading: false, playerLinkedToTeam: action.payload };
        case LIST_PLAYER_LINKED_TO_TEAM_FAILURE:
            return { ...state, loading: false, playerLinkedToTeam: action.payload };

        case LIST_COACH_LINKED_TO_TEAM:
            return { ...state, loading: false };
        case LIST_COACH_LINKED_TO_TEAM_SUCCESS:
            return { ...state, loading: false, coachLinkedToTeam: action.payload };
        case LIST_COACH_LINKED_TO_TEAM_FAILURE:
            return { ...state, loading: false, coachLinkedToTeam: action.payload };

        case LIST_COUNTER_LINKED_TO_TEAM:
            return { ...state, loading: false };
        case LIST_COUNTER_LINKED_TO_TEAM_SUCCESS:
            return { ...state, loading: false, counterLinkedToTeam: action.payload };
        case LIST_COUNTER_LINKED_TO_TEAM_FAILURE:
            return { ...state, loading: false, counterLinkedToTeam: action.payload };

        default:
            return state;
    }
};
