// Prone to change. It currently points at staging, not development.
// Just a way to not break the current dev experience
// eslint-disable-next-line no-unused-vars
import React from "react";
import moment from "moment-timezone";

const env = "production";
const hosts = {
    development: "https://cu-api-dev.herokuapp.com",
    staging: "https://cu-api-staging.herokuapp.com",
    production: "https://cu-api-production.herokuapp.com"
};

// Actual apiUrl is defined on the environment, so that the same code
// can be shared across dev, staging and production apps
export const apiUrl = process.env.REACT_APP_API_URL || hosts[env] || hosts.development;
export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => localStorage.getItem('token');

export const removeToken = () => {
    localStorage.removeItem('token');
};

export const compareValues = (key, order) => {
    return function innerSort(a, b) {
        if (Object.prototype.hasOwnProperty.call(!a, key) || Object.prototype.hasOwnProperty.call(!b, key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
};
// [{id: 1, name: "Saab"}, {id: 2, name: "Fiat"}]
export const statesList = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinoi',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montan',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvani',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const abbrState = {
    'Arizona': 'AZ',
    'Alabama': 'AL',
    'Alaska':'AK',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinoi': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export const groupBy = (list, key) => {
    return list.reduce((accumulator, item) => {
        accumulator[item[key]] = item;
        return accumulator;
    }, {});
};

const rangeArray = (min, max) => {
    return Array(max - min + 1).fill().map((_, idx) => min + idx);
};

const minGrade = 1;
const maxGrade = 15;
export const grades = rangeArray(minGrade, maxGrade);

const minAge = 4;
const maxAge = 30;
export const age = rangeArray(minAge, maxAge);

export const timeInterval = [
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM"
];

export const timeZoneList = [
    "EST",
    "EDT",
    "PSC",
    "PDT",
    "MST",
    "MDT",
    "CST",
    "CDT"
];

const tzDictionary = {
    "MST": 'America/Creston',
    "MDT": 'America/Creston',
    "CDT": "America/Chicago",
    "CST": "America/Chicago",
    "EST": "America/New_York",
    "EDT": "America/New_York",
    "PDT": "America/Los_Angeles",
    "PST": "America/Los_Angeles",
};



const zonedGameTime = (game) => {
    const time = moment.tz(game.datetime, tzDictionary[game.timezone]);
    if (!time.isDST() && game.timezone[1] === "D") {time.add({hours: 1});}
    if (time.isDST() && game.timezone[1] === "S") {time.subtract({hours: 1});};
    return time;
};

export const gameDisplayTime = (game) => zonedGameTime(game).format('h:mm');
export const localAmPmForGame = (game) => zonedGameTime(game).format('A');