import {
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,

    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,

    GAME_END_HOUR_LIST,
    GAME_END_HOUR_SUCCESS,
    GAME_END_HOUR_FALIURE,

    UPDATE_GAME_END_HOUR,
    UPDATE_GAME_END_HOUR_SUCCESS,
    UPDATE_GAME_END_HOUR_FALIURE

} from './constant';

export const getNotificationList = () => ({
   type: GET_NOTIFICATION_LIST
});

export const getNotificationListSuccess = (payload) => ({
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload
});

export const getNotificationListFailure = (payload) => ({
    type: GET_NOTIFICATION_LIST_FAILURE,
    payload
});

export const updateNotification = (payload)  => ({
    type: UPDATE_NOTIFICATION,
    payload
});

export const updateNotificationSuccess = ()  => ({
    type: UPDATE_NOTIFICATION_SUCCESS
});

export const updateNotificationFailure = ()  => ({
    type: UPDATE_NOTIFICATION_FAILURE
});

export const gameEndHourList = () =>({
    type: GAME_END_HOUR_LIST
});

export const gameEndHourSuccess = (payload) =>({
    type: GAME_END_HOUR_SUCCESS,
    payload
});

export const gameEndHourFailure = (payload) =>({
    type: GAME_END_HOUR_FALIURE,
    payload
});

export const updateGameEndHour = (payload) =>({
    type: UPDATE_GAME_END_HOUR,
    payload
});
export const updateGameEndSuccess = () =>({
    type: UPDATE_GAME_END_HOUR_SUCCESS
});

export const updateGameEndFailure = () =>({
    type: UPDATE_GAME_END_HOUR_FALIURE
});
