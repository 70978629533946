/* Saga for Team Management */

import {all, takeEvery, put, call} from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_TEAM_LIST,
    DELETE_TEAM,
    CREATE_TEAM,
    VIEW_TEAM_PLAYER_LIST,
    ASSIGN_PLAYER_TO_TEAM,
    VIEW_TEAM_COACH_LIST,
    ASSIGN_COACH_TO_TEAM,
    VIEW_TEAM_COUNTER_LIST,
    ASSIGN_COUNTER_TO_TEAM,
    SHOW_SINGLE_TEAM,
    GET_TEAM_GAME_LIST,
    VIEW_ALL_SEASONS,
    LIST_PLAYER_LINKED_TO_TEAM,
    LIST_COUNTER_LINKED_TO_TEAM,
    LIST_COACH_LINKED_TO_TEAM,
    DEASSIGN_COACH_FROM_TEAM,
    DEASSIGN_COUNTER_FROM_TEAM,
    LIST_TEAM_WITHOUT_SERIALIZATION,
    CREATE_NON_LEAGUE,
    DEASSIGN_PLAYER_FROM_TEAM
} from '../actions/constant';

import {
    getTeamListSuccess,
    getTeamListFailure,
    getTeamList,
    viewTeamPlayerSuccess,
    viewTeamPlayerFailure,
    viewTeamPlayer,
    viewTeamCoachFailure,
    viewTeamCoachSuccess,
    viewTeamCoach,
    viewTeamCounterFailure,
    viewTeamCounterSuccess,
    viewTeamCounter,
    showSingleTeamFailure,
    showSingleTeamSuccess,
    getTeamGameListSuccess,
    getTeamGameListFailure,
    getAllSeasonsSuccess,
    getAllSeasonsFailure,
    getPlayerLinkedToTeamFailure,
    getPlayerLinkedToTeamSuccess,
    getCoachLinkedToTeamFailure,
    getCoachLinkedToTeamSuccess,
    getCounterLinkedToTeamFailure,
    getCounterLinkedToTeamSuccess,
    getTeamWithoutSerilizationFailure,
    getTeamWithoutSerilizationSuccess
} from "../actions";
import {apiUrl,} from "../utils/helper";

// eslint-disable-next-line require-yield,no-unused-vars

/* = ===== Saga for fetching  Team List ======== */
function* fetchTeamListRequest(actions) {
    let url;
    if(actions.payload && actions.payload.type === "filter") {
        url = `${apiUrl}/v1/teams?keywords${actions.payload.keywords ? `=${actions.payload.keywords}` : ''}&city${actions.payload.city ? `=${actions.payload.city}` : ''}&state${actions.payload.state ? `=${actions.payload.state}` : ''}&active${actions.payload.active || actions.payload.active === false ? `=${actions.payload.active}` : ''}&paid${actions.payload.paid || actions.payload.paid === false ? `=${actions.payload.paid}` : ''}`;
    }
    else if(actions.payload && actions.payload.search) {
        url = `${apiUrl}/v1/teams?search=${actions.payload.search}`;
    }
    else if(actions.payload && actions.payload.league_id){
        url = `${apiUrl}/v1/teams?league_id=${actions.payload.league_id}`;
    }
    else {
        url =`${apiUrl}/v1/teams?remove_associations=true`;
    }
    try {
        const res=yield axios.get(url);
        yield put(getTeamListSuccess(res.data));
    } catch (e) {
        yield  put(getTeamListFailure(e));
    }
}

// /* ====== Saga for Deleting Team By ID  ======== */
function* deleteTeamRequest(actions) {
    const {Id, Back} = actions.payload;
    try {
        yield axios.delete(`${apiUrl}/v1/teams/${Id}`);
        yield put(getTeamList());
        yield Back && Back;
    } catch (e) {
        console.log(e);
    }
}

// /* ====== Saga for Viweing Team By ID  ======== */
function* ListTeamWithourSerilization() {
    try {
        const res = yield axios.get(`${apiUrl}/v1/teams?remove_associations=true`);
        yield put(getTeamWithoutSerilizationSuccess(res.data));

    } catch (e) {
        yield put(getTeamWithoutSerilizationFailure(e));
    }
}

// /* ====== Saga for Viweing Team By ID  ======== */
function* ViewTeamPlayerRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/users/pitchers?team_id=${actions.payload.Id}`);
        yield put(viewTeamPlayerSuccess(res.data));

    } catch (e) {
        yield put(viewTeamPlayerFailure(e));
    }
}

 /* ====== Saga for Viweing Team By ID  ======== */
function* viewTeamCoachRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/users/coaches?team_id=${actions.payload.Id}`);
        yield put(viewTeamCoachSuccess(res.data));

    } catch (e) {
        yield put(viewTeamCoachFailure(e));
    }
}

/* ====== Saga for Viweing Team Counter By ID  ======== */
function* viewTeamCounterRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/users/counters?team_id=${actions.payload.Id}`);
        yield put(viewTeamCounterSuccess(res.data));

    } catch (e) {
        yield put(viewTeamCounterFailure(e));
    }
}

// /* ====== Saga for Viweing Team By ID  ======== */
function* showSingleTeamRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/teams/${actions.payload.Id}`);
        yield put(showSingleTeamSuccess(res.data));

    } catch (e) {
        yield put(showSingleTeamFailure(e));
    }
}

/* ====== Saga for Creating Team   ======== */
function* createTeamRequest(actions) {
    const { data, teamId,back} = actions.payload;
    try {
        if (teamId) {
            yield axios.patch(`${apiUrl}/v1/teams/${teamId}`, data);
            yield back();
        } else {
            yield axios.post(`${apiUrl}/v1/teams`, data);
            yield back();
        }
        yield actions.payload.his.push({
            pathname: actions.payload.his.location.pathname,
            state: {
                id: actions.payload.id,
                name: actions.payload.data.team.name,
                uuid: actions.payload.data.team.uuid,
                state: actions.payload.data.team.state,
                city: actions.payload.data.team.city,
                paid: actions.payload.data.team.paid,
                active: actions.payload.data.team.active,
            }
        });
        yield put(getTeamList());
    } catch (e) {
        console.log(e);
    }
}

/* ======= Saga for assigning player to team  ======== */
function* assignPlayerToTeam(actions) {
    // eslint-disable-next-line camelcase
    const {team_player} =actions.payload;
    try {
       yield axios.post(`${apiUrl}/v1/team_players`,actions.payload);
       yield put(viewTeamPlayer({Id:team_player.team_id}));

    } catch (e) {
        console.log(e);
    }
}

/* ======= Saga for assigning player to team  ======== */
function* deAssignPlayerFromTeam(actions) {
    const {Id, Params,Array} = actions.payload;
    const pass= Array && Array.map(item => item.player_id === Id && item.id );
    const ans=  pass.filter(word => typeof(word)=== "number").toString().split(',')[0];
    try {
        yield axios.delete(`${apiUrl}/v1/team_players/${ans}`);
        yield put(viewTeamPlayer({Id:Params}));
    } catch (e) {
        console.log(e);
    }
}

/* ======= Saga for assigning Coach to team  ======== */
function* assignCoachToTeam(actions) {
    // eslint-disable-next-line camelcase
    const {team_coach} =actions.payload;
    try {
        yield axios.post(`${apiUrl}/v1/team_coaches`,actions.payload);
        yield put(viewTeamCoach({Id:team_coach.team_id}));

    } catch (e) {
        console.log(e);
    }
}

/* ======= Saga for assigning Counter to team  ======== */
function* assignCounterToTeam(actions) {
    // eslint-disable-next-line camelcase
    const {team_counter} =actions.payload;
    try {
        yield axios.post(`${apiUrl}/v1/team_counters`,actions.payload);
        yield put(viewTeamCounter({Id:team_counter.team_id}));

    } catch (e) {
        console.log(e);
    }
}

function * getTeamGameListRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/v1/games?${actions.payload.type}=${actions.payload.id}`);
        yield put(getTeamGameListSuccess(res.data));

    } catch (e) {
        yield put(getTeamGameListFailure(e));
    }

}

// /*= ===== Saga for Viweing League Season  By ID  ========*/
function* AllSeasonRequest() {
    const url = `${apiUrl}/v1/seasons`;
    try {
        const res=yield axios.get(url);
        yield put(getAllSeasonsSuccess(res.data));
    } catch (e) {
        yield put(getAllSeasonsFailure(e));
    }
}

/* Saga for list player linked to team */
function* fetchListPlayerLinkedToTeam(actions) {
    const url = `${apiUrl}/v1/team_players?team_id=${actions.payload}`;
    try {
        const res=yield axios.get(url);
        yield put(getPlayerLinkedToTeamSuccess(res.data));
    } catch (e) {
        yield put(getPlayerLinkedToTeamFailure(e));
    }
}

/* Saga for list Coach linked to team */
function* fetchListCoachLinkedToTeam(actions) {
    const url = `${apiUrl}/v1/team_coaches?team_id=${actions.payload}`;
    try {
        const res=yield axios.get(url);
        yield put(getCoachLinkedToTeamSuccess(res.data));
    } catch (e) {
        yield put(getCoachLinkedToTeamFailure(e));
    }
}

/* Saga for list Counter linked to team */
function* fetchListCounterLinkedToTeam(actions) {
    const url = `${apiUrl}/v1/team_counters?team_id=${actions.payload}`;
    try {
        const res=yield axios.get(url);
        yield put(getCounterLinkedToTeamSuccess(res.data));
    } catch (e) {
        yield put(getCounterLinkedToTeamFailure(e));
    }
}

// /*====== Saga for Deassign coach from Team    ========*/
function* deassignCoachFromTeamRequest(actions) {
    const {Id, Params,Array} = actions.payload;

    const pass= Array && Array.map(item => item.coach_id === Id && item.id );
    const ans=  pass.filter(word => typeof(word)=== "number").toString().split(',')[0];

    try {
        yield axios.delete(`${apiUrl}/v1/team_coaches/${ans}`);
        yield put(viewTeamCoach({Id:Params}));
    } catch (e) {
        console.log(e);
    }
}

// /*====== Saga for Deassign counter from Team    ========*/
function* deassignCounterFromTeamRequest(actions) {
    const {Id, Params,Array} = actions.payload;
    const pass= Array && Array.map(item => item.counter_id === Id && item.id );
    const ans=  pass.filter(word => typeof(word)=== "number").toString().split(',')[0];
    try {
        yield axios.delete(`${apiUrl}/v1/team_counters/${ans}`);
        yield put(viewTeamCounter({Id:Params}));
    } catch (e) {
        console.log(e);
    }
}

/* Saga for create non-league */
function* requestCreateNonLeague(actions) {
    try {
        if(actions.payload.game_id) {
            yield axios.patch(`${apiUrl}/v1/games/${actions.payload.game_id}`,actions.payload.data);
        } else {
            yield axios.post(`${apiUrl}/v1/games`,actions.payload.data);
        }
        yield call(actions.payload.back);
    } catch (e) {
        console.log(e);
    }
}

/* Watch Team List */
export function* watchTeamListAPI() {
    yield takeEvery(GET_TEAM_LIST, fetchTeamListRequest);
}

// /* Watch for Deleting Team */
export function* watchDeleteTeamAPI() {
    yield takeEvery(DELETE_TEAM, deleteTeamRequest);
}

// /* Watch for Deleting Team */
export function* watchCreateTeamAPI() {
    yield takeEvery(CREATE_TEAM, createTeamRequest);
}

/* Watch for Viewing Player of TeaM */
export function* watchViewTeamPlayer() {
    yield takeEvery(VIEW_TEAM_PLAYER_LIST, ViewTeamPlayerRequest);
}

/* Watch for Viewing Coach of Team */
export function* watchViewTeamCoach() {
    yield takeEvery(VIEW_TEAM_COACH_LIST, viewTeamCoachRequest);
}

/* Watch for Viewing Coach of Team */
export function* watchViewTeamCounter() {
    yield takeEvery(VIEW_TEAM_COUNTER_LIST, viewTeamCounterRequest);
}
/* Watch for Assigning Counter to Team */
export function* watchSingleRequestTeamAPI() {
    yield takeEvery(SHOW_SINGLE_TEAM, showSingleTeamRequest);
}
/* Watch for Assigning Team */
export function* watchAssignPlayerToTeam() {
    yield takeEvery(ASSIGN_PLAYER_TO_TEAM, assignPlayerToTeam);
}

/* Watch for Assigning Team */
export function* watchDeassignPlayerFromTeam() {
    yield takeEvery(DEASSIGN_PLAYER_FROM_TEAM, deAssignPlayerFromTeam);
}

/* Watch for Assigning Coach to Team */
export function* watchAssignCoachToTeam() {
    yield takeEvery(ASSIGN_COACH_TO_TEAM, assignCoachToTeam);
}
/* Watch for Assigning Counter to Team */
export function* watchAssignCounterToTeam() {
    yield takeEvery(ASSIGN_COUNTER_TO_TEAM, assignCounterToTeam);
}

/* Watch for Get Team game list */
export function* watchGetTeamGamesList() {
    yield takeEvery(GET_TEAM_GAME_LIST, getTeamGameListRequest);
}
/* Watch for Get Team game list */
export function* watchAllSeason() {
    yield takeEvery(VIEW_ALL_SEASONS, AllSeasonRequest);
}

/* Watch for Get Team game list */
export function* watchPlayerLinkedToTeam() {
    yield takeEvery(LIST_PLAYER_LINKED_TO_TEAM, fetchListPlayerLinkedToTeam);
}

/* Watch for Get Team coach list */
export function* watchCounterLinkedToTeam() {
    yield takeEvery(LIST_COUNTER_LINKED_TO_TEAM, fetchListCounterLinkedToTeam);
}

/* Watch for Get team without serlization */
export function* watchTeamWithoutSerilization() {
    yield takeEvery(LIST_TEAM_WITHOUT_SERIALIZATION, ListTeamWithourSerilization);
}

/* Watch for Get Team counter Linked list */
export function* watchCoachLinkedToTeam() {
    yield takeEvery(LIST_COACH_LINKED_TO_TEAM, fetchListCoachLinkedToTeam);
}

/* Watch deassign coach from team */
export function* watchDeassignCoachFromTeam() {
    yield takeEvery(DEASSIGN_COACH_FROM_TEAM, deassignCoachFromTeamRequest);
}

/* Watch deassign counter from team */
export function* watchDeassignCounterFromTeam() {
    yield takeEvery(DEASSIGN_COUNTER_FROM_TEAM, deassignCounterFromTeamRequest);
}

/* Watch create non league game  */
export function* watchCreateNonLeague() {
    yield takeEvery(CREATE_NON_LEAGUE, requestCreateNonLeague);
}
export default function* rootSaga() {
    yield all([
        watchTeamListAPI(),
        watchDeleteTeamAPI(),
        watchViewTeamPlayer(),
        watchCreateTeamAPI(),
        watchAssignPlayerToTeam(),
        watchViewTeamCoach(),
        watchAssignCoachToTeam(),
        watchViewTeamCounter(),
        watchAssignCounterToTeam(),
        watchSingleRequestTeamAPI(),
        watchGetTeamGamesList(),
        watchAllSeason(),
        watchPlayerLinkedToTeam(),
        watchCounterLinkedToTeam(),
        watchCoachLinkedToTeam(),
        watchDeassignCoachFromTeam(),
        watchDeassignCounterFromTeam(),
        watchCreateNonLeague(),
        watchTeamWithoutSerilization(),
        watchDeassignPlayerFromTeam()
    ]);
}
