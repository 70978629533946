/* Actions of Coach Management */

import {
    GET_COACH_LIST,
    GET_COACH_LIST_SUCCESS,
    GET_COACH_LIST_FAILURE,

    DELETE_COACH,

    VIEW_COACH,
    VIEW_COACH_SUCCESS,
    VIEW_COACH_FAILURE,

    CREATE_COACH

} from "./constant";

/* ======== List of  Coach  Actions ========== */

export const getCoachList = (payload) =>({
    type: GET_COACH_LIST,
    payload
});

export const getCoachListSuccess = (payload) => ({
    type: GET_COACH_LIST_SUCCESS,
    payload
});

export const getCoachListFailure = (payload) => ({
    type: GET_COACH_LIST_FAILURE,
    payload
});

/* ======== Delete  Coach  Actions ========== */

export const deleteCoach = (payload) =>({
    type: DELETE_COACH,
    payload
});

/* Create Coach Actions */

export const createCoach = (payload) =>({
    type: CREATE_COACH,
    payload
});

/* ======== View Coach By ID Actions ========== */

export const viewCoach = () =>({
    type: VIEW_COACH
});

export const viewCoachSuccess = (payload) => ({
    type: VIEW_COACH_SUCCESS,
    payload
});

export const viewCoachFailure = (payload) => ({
    type: VIEW_COACH_FAILURE,
    payload
});

