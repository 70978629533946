import { combineReducers } from "redux";

import LoginReducer from './loginReducer';
import GoverningEntityReducer from './governingEntityReducer';
import LeagueReducer from './leagueManagementReducer';
import TeamReducer from './teamManagementReducer';
import CoachReducer from './coachManagementReducer';
import  CounterReducer from './counterManagementReducer';
import PlayerReducer from './playerManagementReducer';
import BreadCrumbReducer from './breadCrumbReducer';
import GameReducer from './gameManagementReducer';
import SystemReducer from './systemReducer';
import SystemConfigurationReducer from "./systemConfigurationReducer";

const appReducer = combineReducers({
    LoginReducer,
    GoverningEntityReducer,
    LeagueReducer,
    TeamReducer,
    CoachReducer,
    CounterReducer,
    PlayerReducer,
    BreadCrumbReducer,
    GameReducer,
    SystemReducer,
    SystemConfigurationReducer
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
