/* Coach Management Reducer */

import {
    GET_COACH_LIST,
    GET_COACH_LIST_SUCCESS,
    GET_COACH_LIST_FAILURE,

    DELETE_COACH,

    VIEW_COACH,
    VIEW_COACH_SUCCESS,
    VIEW_COACH_FAILURE,

    CREATE_COACH

} from "../actions/constant";

const INIT_STATE = {
    loading: false,
    coachList:[],
    viewCoachByID:null,
};

export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case GET_COACH_LIST:
            return { ...state, loading: true };
        case GET_COACH_LIST_SUCCESS:
            return { ...state, loading: false, coachList: action.payload };
        case GET_COACH_LIST_FAILURE:
            return { ...state, loading: false, coachList: action.payload };

        case DELETE_COACH:
            return { ...state, loading: false};

        case CREATE_COACH:
            return { ...state, loading: false};

        case VIEW_COACH:
            return { ...state, loading: false };
        case VIEW_COACH_SUCCESS:
            return { ...state, loading: false, viewCoachByID: action.payload };
        case VIEW_COACH_FAILURE:
            return { ...state, loading: false, viewCoachByID: action.payload };
        default:
            return state;
    }
};
