/* Actions of Counter Management */

import {
    GET_COUNTER_LIST,
    GET_COUNTER_LIST_SUCCESS,
    GET_COUNTER_LIST_FAILURE,

    DELETE_COUNTER,

    // VIEW_COUNTER,
    VIEW_COUNTER_SUCCESS,
    VIEW_COUNTER_FAILURE,
    CREATE_COUNTER
} from "./constant";

/*= ======= List of  Counter  Actions ========== */

export const getCounterList = (payload) => ({
    type: GET_COUNTER_LIST,
    payload
});

export const getCounterListSuccess = (payload) => ({
    type: GET_COUNTER_LIST_SUCCESS,
    payload
});

export const getCounterListFailure = (payload) => ({
    type: GET_COUNTER_LIST_FAILURE,
    payload
});

/*= ======= Delete  Counter By ID  Actions ========== */

export const deleteCounter = (payload) => ({
    type: DELETE_COUNTER,
    payload
});


/*= ======= Create  Counter ========== */

export const createCounter = (payload) => ({
    type: CREATE_COUNTER,
    payload
});

/* View counter by ID */
export const viewCounterSuccess = (payload) => ({
    type: VIEW_COUNTER_SUCCESS,
    payload
});

export const viewCounterFailure = (payload) => ({
    type: VIEW_COUNTER_FAILURE,
    payload
});

;
