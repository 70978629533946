import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';

import {LOGIN_USER, LOGOUT_USER, GET_USER_DETAILS} from '../actions/constant';
import { loginUserSuccess, loginUserFailure, getUserDetailsSuccess, getUserDetailsFail } from "../actions";
import {apiUrl, removeToken, setToken} from "../utils/helper";
import { setAuthToken } from "../utils/axios";

function* loginUserRequest(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/oauth/token`, actions.payload);
        if(res.status === 200) {
            yield call(setToken, res.data.access_token);
            yield put(loginUserSuccess(res.data));
            yield call(setAuthToken, res.data.access_token);
        }
        else yield put(loginUserFailure(res));
    }catch (e) {
        yield put(loginUserFailure(e));
    }
}

function* logoutUserRequest() {
    try {
        yield removeToken();
    } catch (e) {
        console.log(e);
    }
}

function* getUserDetailsRequest() {
    try{
        const res = yield axios.get(`${apiUrl}/v1/me`);
        yield put(getUserDetailsSuccess(res.data));
    }catch (e) {
        yield put(getUserDetailsFail(e));
    }
}

export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logoutUserRequest);
}

export function* watchUserDetailsAPI() {
    yield takeEvery(GET_USER_DETAILS, getUserDetailsRequest);
}

export default function* rootSaga() {
    yield all([
        watchLoginUserAPI(),
        watchLogoutUser(),
        watchUserDetailsAPI()
    ]);
}